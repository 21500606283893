import {Center, CircularProgress, Flex, Image, Skeleton, Text} from '@chakra-ui/react'
import {FC, useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useMonthSliceStore, useOverviewStore} from '@kaef/common/stores'
import {api} from 'services/api'
import {Button, ButtonGroup, IconButton, Stat} from 'components'
import {SliceDataList} from '../SliceDataList/SliceDataList'
import {TotalMonthChart} from './components/TotalMonthChart'
import {goalSelector} from '@kaef/common/api/goals/goals.selector'
import {SlicedMonthChart} from './components/SlicedMonthChart'
import {mapSlice, prepareOverviewRequestData, secondsToString} from '@kaef/common/utils/helpers'
import {MonthBarChart} from './components/MonthBarChart'
import Monitor from 'assets/monitor.png'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {paths} from 'shared/constants/paths'
import {secondaryGroupContainerProps} from '../utils'
import {
  logChangeOverview,
  logChangeSlice,
  logOpenChart, logOpenDay,
  logOpenPaywall, logOpenShareModal,
  logProjectOpenDetails,
  logProjectToggleVisibility
} from 'services/analytics/analytics'
import {ERange, EShareType, ISliceData} from '@kaef/common/types'
import {useInitFromUrlParams} from 'shared/hooks/useInitFromUrlParams'
import {overviewKeys} from '@kaef/common/api/overview/overview.keys'
import {projectsKeys} from '@kaef/common/api/projects/projects.keys'
import {useQueryClient} from '@tanstack/react-query'
import {format} from 'date-fns'

const MonthLayout: FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
  const {id} = useParams()

  const month = useOverviewStore((state) => state.month)
  const setDay = useOverviewStore((state) => state.setDay)
  const [slice, setSlice, chart, setChart] = useMonthSliceStore((state) => [state.slice, state.setSlice, state.chart, state.setChart])

  const {isLoading, isError, data} = api.useOverviewMonthQuery(prepareOverviewRequestData(month, ERange.MONTH, id))
  const goalQuery = api.useGoalsQuery()
  const projectsQuery = api.useProjectsQuery()
  const saveProjectMutation = api.useSaveProjectMutation()
  const userQuery = api.useUserInfoQuery()
  const shareMutation = api.useShareToTwitterMutation()

  const goalDuration = goalSelector('DURATION')(goalQuery.data)
  const goalDays = goalSelector('DAYS')(goalQuery.data)

  const sliceKey = useInitFromUrlParams(ERange.WEEK, setChart)

  const isNoPlugins = !isLoading && userQuery.data?.plugins.length === 0

  const onChangeSlice = (value: string) => {
    logChangeSlice(value, 'month')
    logChangeOverview('month', chart, value)
    const findSlice = data?.slices.find((slice) => slice.type === value)
    if (findSlice) {
      setSlice(findSlice)
    }
  }

  const onDayClick = useCallback((day: Date, isLocked?: boolean) => {
    if (isLocked) {
      logOpenPaywall('month_day_click')
      navigate(paths.paywall.path, {state: {backgroundLocation: location}})
      return
    }
    setDay(day)
    logOpenDay(chart)
    navigate(`${paths.overview.path}/day/${id || ''}`)
  }, [navigate, id, setDay, chart])

  const onOpenMonthCalendarTotal = () => {
    setChart('MONTH_CALENDAR_TOTAL')
    logChangeOverview('month', 'MONTH_CALENDAR_TOTAL', slice?.type)
    logOpenChart('MONTH_CALENDAR_TOTAL')
  }

  const onOpenMonthCalendarCompare = () => {
    setChart('MONTH_CALENDAR_COMPARE')
    logChangeOverview('month', 'MONTH_CALENDAR_COMPARE', slice?.type)
    logOpenChart('MONTH_CALENDAR_COMPARE')
  }

  const onOpenMonthTotal = () => {
    setChart('MONTH_TOTAL')
    logChangeOverview('month', 'MONTH_TOTAL', slice?.type)
    logOpenChart('MONTH_TOTAL')
  }

  const onEditProject = (data: ISliceData) => {
    navigate(`${paths.editProject.path}/${data.uuid}`, {state: {backgroundLocation: location}})
  }

  const onNavToSliceDetails = (data: ISliceData, isLocked?: boolean) => {
    if (isLocked) {
      logOpenPaywall('month_slice_details')
      navigate(paths.paywall.path, {state: {backgroundLocation: location}})
      return
    }
    logProjectOpenDetails()
    navigate(`${paths.overview.path}/month/${data.uuid}`)
  }

  const onToggleVisibility = (data: ISliceData) => {
    if (!projectsQuery.data) {
      return
    }
    const findProject = projectsQuery.data.find((item) => item.id === data.uuid)
    if (findProject) {
      logProjectToggleVisibility()
      saveProjectMutation.mutate(
        {
          ...findProject,
          show: !findProject.show
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(overviewKeys.all())
            queryClient.invalidateQueries(projectsKeys.all())
          }
        }
      )
    }
  }

  const onShare = () => {
    if (slice?.byDays.some((item) => item.isLocked) || slice?.data.some(item => item.isLocked)) {
      logOpenPaywall('month_share')
      navigate(paths.paywall.path, {state: {backgroundLocation: location}})
      return
    }
    navigate(`${paths.share.path}?date=${format(month, 'yyyy-MM-dd')}&type=${EShareType.MONTH}${!id ? '' : `&projectUid=${id}`}`, {state: {backgroundLocation: location}})
    logOpenShareModal('month', !!id, chart, slice?.type)
  }

  useEffect(() => {
    if (!data?.slices) {
      return
    }
    if (slice && data?.slices.find((item) => item.type === slice.type)) {
      return
    }
    if (!sliceKey) {
      setSlice(data?.slices[0])
      return
    }
    const findSlice = data?.slices.find((slice) => slice.type === sliceKey)
    if (findSlice) {
      setSlice(findSlice)
    } else {
      setSlice(data?.slices[0])
    }
  }, [data])

  const currentSlice = data?.slices?.find((_slice) => _slice.type === slice?.type)

  const slices = data?.slices?.map((slice) => ({
    label: slice.title,
    value: slice.type
  }))

  const mappedSliceData = mapSlice(currentSlice?.data)

  const {total, average} = data || {total: 0, average: 0}

  return (
    <Flex flexDirection={'column'} w={'100%'}>
      {isError && <Flex>Request Error</Flex>}
      {!isError && isNoPlugins && (
        <Flex alignItems={'center'} flexDirection={'column'}>
          <Image w={'210px'} mt={'10px'} mb={'10px'} src={Monitor} />
          <Text mt={'20px'} color={'white.1'} textStyle={'md'}>
            {t('overview.noPlugin')}
          </Text>
        </Flex>
      )}
      {!isError && !isNoPlugins && (
        <>
          {isLoading && (
            <Center pb={'42px'} h={'516px'} w={'100%'}>
              <CircularProgress isIndeterminate color={'accent.1'} />
            </Center>
          )}
          {!isLoading && (
            <Flex w={'100%'} flexDirection={['column-reverse', 'column-reverse', 'row']}>
              <Flex w={'100%'} mt={'15px'} mr={'40px'}>
                {chart === 'MONTH_CALENDAR_TOTAL' && (
                  <TotalMonthChart
                    withoutGoal={!!id}
                    onDayClick={onDayClick}
                    goalDuration={goalDuration}
                    goalDays={goalDays}
                    data={data?.totalList}
                    month={month}
                  />
                )}
                {chart === 'MONTH_CALENDAR_COMPARE' && (
                  <SlicedMonthChart
                    onDayClick={onDayClick}
                    goalDuration={goalDuration}
                    data={currentSlice?.byDays}
                    sliceMap={mappedSliceData}
                    month={month}
                  />
                )}
                {chart === 'MONTH_TOTAL' && (
                  <MonthBarChart
                    onDayClick={onDayClick}
                    data={currentSlice?.byDays}
                    sliceData={currentSlice?.data}
                    month={month}
                  />
                )}
              </Flex>
              <Flex
                flexDirection={['row', 'row', 'column']}
                justifyContent={['space-between', 'space-between', 'flex-start']}
                w={['100%', '100%', '160px']}
                flexShrink={0}
                mt={'16px'}>
                <Flex mb={'40px'}>
                  <IconButton
                    minWidth={'32px'}
                    w={'32px'}
                    h={'32px'}
                    mr={'6px'}
                    icon={'time1'}
                    variant={chart === 'MONTH_CALENDAR_TOTAL' ? 'accent' : 'ghost'}
                    onClick={onOpenMonthCalendarTotal}
                    aria-label={'month-day-total'}
                  />
                  <IconButton
                    minWidth={'32px'}
                    w={'32px'}
                    h={'32px'}
                    icon={'pieChart'}
                    mr={'6px'}
                    variant={chart === 'MONTH_CALENDAR_COMPARE' ? 'accent' : 'ghost'}
                    onClick={onOpenMonthCalendarCompare}
                    aria-label={'month-day-average'}
                  />
                  <IconButton
                    minWidth={'32px'}
                    w={'32px'}
                    h={'32px'}
                    icon={'graph1'}
                    variant={chart === 'MONTH_TOTAL' ? 'accent' : 'ghost'}
                    onClick={onOpenMonthTotal}
                    aria-label={'month-day-barchart'}
                  />
                </Flex>
                <Flex flexDirection={['row', 'row', 'column']} gap={'12px'}>
                  <Stat
                    value={secondsToString(Math.round(total))}
                    title={t('overview.month.total')}
                  />
                  <Stat value={secondsToString(Math.round(average))} title={t('overview.month.avg')} />
                  <Button isLoading={shareMutation.isLoading} h={'28px'} w={'90px'} size={'sm'} variant={'withIcon'}
                          iconProps={{width: 18, height: 18}}
                          iconPosition={'left'}
                          icon={'share'}
                          onClick={onShare}>
                    <Text textStyle={'md'} ml={'4px'}>Share</Text>
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          )}
          <Flex justifyContent={'center'}>
            {isLoading ? (
              <Skeleton borderRadius={'8px'} w={'100%'} h={'48px'} />
            ) : (
              <ButtonGroup
                containerProps={secondaryGroupContainerProps}
                variant='secondary'
                options={slices}
                value={slice?.type}
                onChange={onChangeSlice}
              />
            )}
          </Flex>
          {!isLoading && !isError && total === 0 && (
            <Flex mt={'40px'} mb={'40px'} w={'100%'} flexDirection={'column'} alignItems={'center'}>
              <Text textStyle={'md'} mb={'4px'}>
                {t('overview.noActivityTitle')}
              </Text>
              <Text textAlign={'center'} maxW={'280px'} textStyle={'sm'} color={'white.3'}>
                {t('overview.noActivityDescription')}
              </Text>
            </Flex>
          )}
          <SliceDataList
            onToggleVisibility={onToggleVisibility}
            onEditProject={onEditProject}
            disabled={currentSlice?.type !== 'PROJECT' || !!id}
            withToolkit={currentSlice?.type === 'PROJECT'}
            withEmoji={currentSlice?.type === 'PROJECT'}
            data={currentSlice?.data}
            isLoading={isLoading}
            onClick={onNavToSliceDetails}
          />
        </>
      )}
    </Flex>
  )
}

export default MonthLayout
