export const colors = {
  black: {
    1: "#0A0A0A",
    2: "#131416",
    3: "#1A1B1D",
    "3-40": "#1A1B1D66",
    4: "#26272A",
    5: "#343537",
    "5-40": "#34353766",
    6: "#13191A",
  },
  white: {
    1: "#FBFBFB",
    "1-0": "#FBFBFB00",
    "1-10": "#FBFBFB1A",
    "1-05": "#FBFBFB0D",
    "1-20": "#FBFBFB33",
    "1-50": "#FBFBFB80",
    2: "#B1B2B6",
    3: "#8A8C92",
    "3-05": "#8A8C920D",
    "3-10": "#8A8C921A",
    "3-20": "#8A8C9233",
    "3-50": "#8A8C9280",
  },
  accent: {
    1: "#4431EF",
    2: "#6959FF",
    "1-05": "#4431EF0D",
    "1-10": "#4431ef1a",
    "1-30": "#4431EF4D",
    "1-50": "#4431EF80",
  },
  state: {
    positive: "#67C48F",
    "positive-10": "#67C48F1A",
    "positive-05": "#67C48F0D",
    "positive-50": "#67C48F80",
    neutral: "#F5C560",
    negative: "#DF745F",
    "negative-0": "#DF745F00",
    "negative-05": "#DF745F0D",
    "negative-30": "#DF745F4D",
    "negative-50": "#DF745F80",
  },
  logo: {
    neon: "#44DCB3",
    "neon-10": "#44DCB31A",
    yellow: "#AED046",
    orange: "#FF886C",
    white: "#FFFFFF",
    black: "#000000",
  }
};

export const chartColors = [
  '#D7A078',
  '#5E84EB',
  '#86C166',
  '#D280A9',
  '#9B5F1F',
  '#689DDB',
  '#5CBDA6',
  '#6A64C9',
  '#666666',
  '#F4A259',
  '#6DB6FF',
  '#8CD17D',
  '#F2928D',
  '#A8A8A8',
  '#BD4F6C'
]
