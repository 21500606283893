import React, {FC} from 'react'
import {Link as DomLink, useNavigate} from 'react-router-dom'
import {paths} from 'shared/constants/paths'
import {GridItem, Text, Image, Flex, Heading, Button, Divider} from '@chakra-ui/react'
import ImageDesktop from 'assets/landingOverviewDesktop.png'
import ImageMobile from 'assets/langingOverviewMobile.png'
import HeadingGroup from './components/HeadingGroup'
import FeatureItem from './components/FeatureItem'
import {useTranslation} from 'react-i18next'
import {FeatureList} from './components/FeatureList'
import {PricingBlock} from 'components/PricingBlock/PricingBlock'
import {subscriptions} from '@kaef/common/ui/constants/subscriptions'
import {useRemoteConfig} from 'services/firebase/useRemoteConfig'
import {PLUGINS} from '@kaef/common/ui/constants/plugins'
import {PluginIcon} from 'components'

const Home: FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const {isSubscriptionsEnabled} = useRemoteConfig()

  return (
    <GridItem
      area={'main'}
      display={'flex'}
      flexDirection={'column'}
      gridGap={'25px'}
      justifyContent={'start'}
      alignItems={'center'}>
      <Heading as={'h1'} textAlign={'center'} fontSize={['28px', '28px', '56px']}>
        {t('landing.header')}
      </Heading>
      <Text color={'white.2'} fontSize={['16px', '16px', '20px']} maxW={'640px'} textAlign={'center'}>
        {t('landing.headerDesc')}
      </Text>
      <Button
        fontSize={'20px'}
        textTransform={'uppercase'}
        padding={'15px 35px'}
        margin={'0 0 50px'}
        height={'auto'}
        boxShadow={'0 0 0 2px black, 0 0 0 3px #4431EF'}
        as={DomLink}
        to={paths.authorization.path}
        variant={'accent'}>
        {t('landing.joinButton')}
      </Button>
      <Image src={ImageDesktop} display={['none', 'none', 'inline-block']} marginBottom={'30px'} />
      <Image src={ImageMobile} display={['inline-block', 'inline-block', 'none']} marginBottom={'30px'} />
      <HeadingGroup title={t('landing.features.title')} subtitle={t('landing.features.subtitle')} />
      <Flex
        gridGap={'36px 70px'}
        flexDirection={['column', 'column', 'row']}
        margin={['0 0 30px', '0 0 30px', '0 0 85px']}>
        <FeatureItem
          heading={t('landing.features.first.heading')}
          description={t('landing.features.first.description')}
        />
        <FeatureItem
          heading={t('landing.features.second.heading')}
          description={t('landing.features.second.description')}
        />
        <FeatureItem
          heading={t('landing.features.third.heading')}
          description={t('landing.features.third.description')}
        />
      </Flex>
      <HeadingGroup title={t('landing.privacy.title')} subtitle={t('landing.privacy.subtitle')} />
      <Flex
        gridGap={'36px 70px'}
        flexDirection={['column', 'column', 'row']}
        margin={['0 0 30px', '0 0 30px', '0 0 85px']}>
        <Text color={'white.3'} textAlign={'center'} textStyle={'lg'}>
          {t('landing.privacy.disclaimer')}
        </Text>
      </Flex>

      <HeadingGroup title={t('landing.plugins.title')} subtitle={t('landing.plugins.subtitle')} />
      <Flex
        gridGap={'36px 70px'}
        flexDirection={['column', 'column', 'row']}
        margin={['0 0 30px', '0 0 30px', '0 0 85px']}>
        <Flex alignItems={'center'} w={'100%'} maxW={'700px'} gap={'12px'} flexWrap={'wrap'}>
          {PLUGINS.filter(item => item.type !== 'wakatime-import').map((item) => {
            return (
              <Flex
                flexDirection={'column'}
                borderRadius={'8px'}
                borderWidth={'1px'}
                borderColor={'black.5-40'}
                backgroundColor={'white.1-05'}
                display={'flex'}
                p={'40px 0'}
                key={item.name}
                w={'calc(25% - 9px)'}
                _hover={{
                  borderColor: 'accent.1',
                  backgroundColor: 'accent.1-05',
                }}
                justifyContent={'center'}
                alignItems={'center'}>
                <PluginIcon type={item.icon} width={'40px'} height={'40px'} />
                <Text textStyle={'md'} mt={'8px'}>
                  {item.name}
                </Text>
              </Flex>
            )
          })}
        </Flex>
      </Flex>

      <HeadingGroup title={t('landing.roadmap.title')} subtitle={t('landing.roadmap.subtitle')} />
      <Flex flexDirection={'column'} width={'100%'} margin={['0 0 30px', '0 0 30px', '0 0 85px']}>
        <Flex flexDirection={['column', 'column', 'row']} justifyContent={'flex-start'} width={'100%'}>
          <Flex flexDirection={'column'} width={'100%'}>
            <Text as={'h2'} width={'100%'} textStyle={'lg'}>
              {t('landing.roadmap.done')}
            </Text>
            <Divider mt={'24px'} mb={'24px'} />
            <FeatureList
              features={[
                t('landing.roadmap.list.activityTracker'),
                t('landing.roadmap.list.goals'),
                t('landing.roadmap.list.jetbrains'),
                t('landing.roadmap.list.vscode'),
                t('landing.roadmap.list.wakatime'),
              ]}
              mode={'done'}
            />
          </Flex>
          <Flex flexDirection={'column'} width={'100%'} mt={['44px', '44px', 0]}>
            <Text as={'h2'} textStyle={'lg'} width={'100%'}>
              {t('landing.roadmap.comingSoon')}
            </Text>
            <Divider mt={'24px'} mb={'24px'} />
            <FeatureList
              features={[
                t('landing.roadmap.list.mobile'),
                t('landing.roadmap.list.leaderboards'),
                t('landing.roadmap.list.publicProfile'),
                t('landing.roadmap.list.teams'),
                t('landing.roadmap.list.browser'),
                t('landing.roadmap.list.desktop'),
                t('landing.roadmap.list.watch'),
              ]}
              badge={t('landing.roadmap.wearehere') as string}
              mode={'inprogress'}
            />
          </Flex>
        </Flex>
      </Flex>

      {isSubscriptionsEnabled && (
        <>
          <HeadingGroup title={t('landing.pricing.title')} subtitle={t('landing.pricing.subtitle')} />
          <Flex
            gridGap={'0 70px'}
            flexDirection={['column', 'column', 'row']}
            justifyContent={'flex-start'}
            width={'100%'}
            padding={'20px 0 0'}>
            {subscriptions.filter(item => item.id !== 'trial').map((item, idx) => (
              <PricingBlock
                onClick={() => navigate(paths.authorization.path)}
                buttonText={t('landing.pricing.getStarted') as string}
                key={idx}
                item={item}
                isAccent={item.id === 'pro_month'}
                badgeColor={item.id === 'pro_year' ? 'accent.1-30' : 'black.5'}
              />
            ))}
          </Flex>
        </>
      )}
    </GridItem>
  )
}

export default Home
