import {ERange, TOverviewRequest} from '../../types'
import {prepareOverviewRequestData} from '../../utils/helpers'

const mapDataToKeys = (data?: TOverviewRequest) => {
  if (!data) {
    return ['nodata']
  }
  const result = []
  if (data.range) {
    result.push(data.range)
  }
  if (data.date) {
    result.push(data.date)
  }
  if (data.filter) {
    Object.entries(data.filter).forEach(entry => {
      result.push(`${entry[0]}:${entry[1]}`)
    })
  }

  return result
}

export const overviewForDateKeys = (date: Date) => {
  const todayDayRequest = prepareOverviewRequestData(date, ERange.DAY)
  const todayWeekRequest = prepareOverviewRequestData(date, ERange.WEEK)
  const todayMonthRequest = prepareOverviewRequestData(date, ERange.MONTH)
  const todayYearRequest = prepareOverviewRequestData(date, ERange.YEAR)
  return [
    overviewKeys.day(todayDayRequest),
    overviewKeys.week(todayWeekRequest),
    overviewKeys.month(todayMonthRequest),
    overviewKeys.year(todayYearRequest),
    overviewKeys.goalsByRange(todayDayRequest),
    overviewKeys.goalsByRange(todayWeekRequest),
    overviewKeys.goalsByRange(todayMonthRequest),
    overviewKeys.goalsByRange(todayYearRequest),
  ]
}

export const overviewKeys = {
  all: () => ["overview"],
  dayAll: () => [...overviewKeys.all(), "day"],
  day: (data: TOverviewRequest) => [...overviewKeys.all(), "day", ...mapDataToKeys(data)],
  week: (data: TOverviewRequest) => [...overviewKeys.all(), "week", ...mapDataToKeys(data)],
  monthAll: () => [...overviewKeys.all(), "month"],
  month: (data: TOverviewRequest) => [...overviewKeys.all(), "month", ...mapDataToKeys(data)],
  year: (data: TOverviewRequest) => [...overviewKeys.all(), "year", ...mapDataToKeys(data)],
  goals: () => [...overviewKeys.all(), "goals"],
  goalsByRange: (data: TOverviewRequest) => [...overviewKeys.all(), "goals", ...mapDataToKeys(data)],
};
