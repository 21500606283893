import React, {FC, useEffect, useMemo, useState} from 'react'
import {Button, IconButton, Input, Modal} from '../../UI'
import {useSearchParams} from 'react-router-dom'
import {api} from 'services/api'
import {
  Center,
  CircularProgress,
  Text,
  Image,
  Divider,
  InputRightElement,
  InputGroup
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {useGoBack} from 'shared/utils/navigation.helper'
import {paths} from 'shared/constants/paths'
import {ESharePlatform, EShareType} from '@kaef/common/types'
import {format} from 'date-fns'
import {getFacebookUrl, getLinkedinUrl, getTwitterUrl} from '@kaef/common/utils/helpers'
import {logShare} from 'services/analytics/analytics'
import copy from 'copy-to-clipboard'

interface IProps {

}

interface IRequestParams {
  date: string
  projectUid?: string
  type: EShareType
}

export const ShareModal: FC<IProps> = ({}) => {
  const {t} = useTranslation()
  const [searchParams, _] = useSearchParams()
  const [requestParams, setRequestParams] = useState<IRequestParams | null>(null)

  const shareMutation = api.useShareToTwitterMutation()

  const goBack = useGoBack()

  const titleDate = useMemo(() => {
    if (!requestParams) {
      return ''
    }
    switch (requestParams?.type) {
      case EShareType.DAY:
        return format(new Date(requestParams.date), 'dd MMM yyyy')
      case EShareType.WEEK:
        return `${format(new Date(requestParams.date), 'dd MMM')} - ${format(new Date(requestParams.date), 'dd MMM yyyy')}`
      case EShareType.MONTH:
        return format(new Date(requestParams.date), 'MMMM yyyy')
      case EShareType.YEAR:
        return format(new Date(requestParams.date), 'yyyy')
    }
  }, [requestParams])

  const onShare = (platform: ESharePlatform) => {
    if (!shareMutation.data) {
      return
    }
    switch (platform) {
      case ESharePlatform.TWITTER:
        window.open(getTwitterUrl(shareMutation.data.text, shareMutation.data.shareUrl || ''))
        logShare(ESharePlatform.TWITTER, requestParams!.type, !!requestParams?.projectUid)
        break
      case ESharePlatform.LINKEDIN:
        window.open(getLinkedinUrl(shareMutation.data.text, shareMutation.data.shareUrl || ''))
        logShare(ESharePlatform.LINKEDIN, requestParams!.type, !!requestParams?.projectUid)
        break
      case ESharePlatform.FACEBOOK:
        window.open(getFacebookUrl(shareMutation.data.text, shareMutation.data.shareUrl || ''))
        logShare(ESharePlatform.FACEBOOK, requestParams!.type, !!requestParams?.projectUid)
        break
    }
  }

  const onClose = () => {
    goBack(paths.share.backgroundPath)
  }

  useEffect(() => {
    if (requestParams) {
      shareMutation.mutate(requestParams)
    }
  }, [requestParams])

  useEffect(() => {
    const date = searchParams.get('date')
    const type = searchParams.get('type') as EShareType
    const projectUid = searchParams.get('projectUid')
    if (!date || !type) {
      onClose()
      return
    }
    setRequestParams({
      date,
      type,
      projectUid: projectUid || undefined
    })
  }, [searchParams])


  return (
    <Modal size={'lg'} onClose={onClose}>
      <Text textStyle={'lg'}>{t('share.title', {value: titleDate})}</Text>
      {shareMutation.isLoading && (
        <Center height={'200px'}>
          <CircularProgress isIndeterminate color={'accent.1'} />
        </Center>
      )}
      {!shareMutation.isLoading && (
        <>
          <Image mt={'24px'} mb={'16px'} src={shareMutation.data?.imageUrl} />
          <InputGroup>
            <Input value={shareMutation.data?.shareUrl} paddingRight={'40px'} color={'white.3'} />
            <InputRightElement>
              <IconButton
                icon={'copy'}
                variant={'ghost'}
                onClick={() => copy(shareMutation.data?.shareUrl || '')}
                aria-label={'copyToClipboard'}
              />
            </InputRightElement>
          </InputGroup>
          <Divider orientation={'horizontal'} mt={'24px'} mb={'24px'} />
          <Button variant={'withIcon'} icon={'twitter'}
                  mb={'16px'}
                  onClick={() => onShare(ESharePlatform.TWITTER)}>{t('share.shareIn', {value: 'X'})}</Button>
          <Button variant={'withIcon'} icon={'linkedin'}
                  mb={'16px'}
                  onClick={() => onShare(ESharePlatform.LINKEDIN)}>{t('share.shareIn', {value: 'LinkedIn'})}</Button>
          <Button variant={'withIcon'} icon={'facebook'}
                  mb={'16px'}
                  onClick={() => onShare(ESharePlatform.FACEBOOK)}>{t('share.shareIn', {value: 'Facebook'})}</Button>


        </>
      )}
    </Modal>
  )
}

export default ShareModal
