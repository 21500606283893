import {Box, Button, Flex, Text} from '@chakra-ui/react'
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  differenceInCalendarDays, endOfMonth,
  endOfWeek, endOfYear,
  format,
  isToday,
  isWithinInterval,
  parse,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns'
import {Icon, IconButton} from 'components'
import React, {FC, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {
  useDaySliceStore,
  useMonthSliceStore,
  useOverviewStore,
  useWeekSliceStore,
  useYearSliceStore,
} from '@kaef/common/stores'
import {EAbility, ERange, ISlice, TChart} from '@kaef/common/types'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {paths} from 'shared/constants/paths'
import {api} from 'services/api'
import {useRemoteConfig} from 'services/firebase/useRemoteConfig'
import {useIsPrevRangeLocked} from './useIsPrevRangeLocked'
import {logChangeOverview, logDayChange, logOpenPaywall} from 'services/analytics/analytics'

type TRange = ERange

interface IProps {
  range: TRange
}

const isCurrentPeriod = (value: Date, range: TRange) => {
  switch (range) {
    case 'day':
      return isToday(value)
    case 'week':
      return isWithinInterval(new Date(), {
        start: value,
        end: addWeeks(value, 1)
      })
    case 'month':
      return isWithinInterval(new Date(), {
        start: value,
        end: addMonths(value, 1)
      })
    case 'year':
      return isWithinInterval(new Date(), {
        start: value,
        end: addYears(value, 1)
      })
  }
}

export const RangeSwitcher: FC<IProps> = ({range}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const {t} = useTranslation()
  const {isSubscriptionsEnabled, freeDaysPeriod} = useRemoteConfig()
  const userInfoQuery = api.useUserInfoQuery()

  const isFullAccess = !isSubscriptionsEnabled || userInfoQuery.data?.subscription.abilities.includes(EAbility.INFINITY_HISTORY)

  const [day, setDay, week, setWeek, month, setMonth, year, setYear] = useOverviewStore((state) => [
    state.day,
    state.setDay,
    state.week,
    state.setWeek,
    state.month,
    state.setMonth,
    state.year,
    state.setYear
  ])

  const [dayChart, daySlice] = useDaySliceStore((state) => [state.chart, state.slice])
  const [weekChart, weekSlice] = useWeekSliceStore((state) => [state.chart, state.slice])
  const [monthChart, monthSlice] = useMonthSliceStore((state) => [state.chart, state.slice])
  const [yearChart, yearSlice] = useYearSliceStore((state) => [state.chart, state.slice])

  const onChangeRef = useRef<(date: Date) => void>(setDay)
  const valueRef = useRef<Date>(day)
  const rangeRef = useRef<ERange>(range)
  const chartRef = useRef<TChart>(dayChart)
  const sliceRef = useRef<ISlice | undefined>(daySlice)
  const isPrevLockedRef = useRef(false)
  const locationRef = useRef(location)
  rangeRef.current = range

  switch (rangeRef.current) {
    case 'day':
      valueRef.current = day
      onChangeRef.current = setDay
      chartRef.current = dayChart
      sliceRef.current = daySlice
      break
    case 'week':
      onChangeRef.current = setWeek
      valueRef.current = week
      chartRef.current = weekChart
      sliceRef.current = weekSlice
      break
    case 'month':
      onChangeRef.current = setMonth
      valueRef.current = month
      chartRef.current = monthChart
      sliceRef.current = monthSlice
      break
    case 'year':
      onChangeRef.current = setYear
      valueRef.current = year
      chartRef.current = yearChart
      sliceRef.current = yearSlice
      break
  }

  isPrevLockedRef.current = useIsPrevRangeLocked(range, valueRef.current, freeDaysPeriod) && !isFullAccess

  const onRedirectToPaywall = () => {
    onToday()
    logOpenPaywall('day_change')
    navigate(`${paths.paywall.path}`, {state: {backgroundLocation: locationRef.current}})
    return
  }

  const onDayFromParam = (date: Date) => {
    switch (rangeRef.current) {
      case 'day':
        onChangeRef.current(date)
        break
      case 'week':
        if (!isFullAccess && differenceInCalendarDays(new Date(), endOfWeek(date, {weekStartsOn: 1})) > freeDaysPeriod) {
          return onRedirectToPaywall()
        }
        onChangeRef.current(startOfWeek(date, {weekStartsOn: 1}))
        break
      case 'month':
        if (!isFullAccess && differenceInCalendarDays(new Date(), endOfMonth(date)) > freeDaysPeriod) {
          return onRedirectToPaywall()
        }
        onChangeRef.current(startOfMonth(date))
        break
      case 'year':
        if (!isFullAccess && differenceInCalendarDays(new Date(), endOfYear(date)) > freeDaysPeriod) {
          return onRedirectToPaywall()
        }
        onChangeRef.current(startOfYear(date))
        break
    }
  }

  const onToday = () => {
    logChangeOverview(rangeRef.current, chartRef.current, sliceRef.current?.type)
    logDayChange(rangeRef.current, chartRef.current, sliceRef.current?.type)
    onDayFromParam(new Date())
  }

  const onNext = () => {
    if (isCurrentPeriod(valueRef.current, rangeRef.current)) {
      return
    }
    logChangeOverview(rangeRef.current, chartRef.current, sliceRef.current?.type)
    logDayChange(rangeRef.current, chartRef.current, sliceRef.current?.type)
    switch (rangeRef.current) {
      case 'day':
        onChangeRef.current(addDays(valueRef.current, 1))
        break
      case 'week':
        onChangeRef.current(addWeeks(valueRef.current, 1))
        break
      case 'month':
        onChangeRef.current(addMonths(valueRef.current, 1))
        break
      case 'year':
        onChangeRef.current(addYears(valueRef.current, 1))
        break
    }
  }
  const onPrevious = () => {
    if (isPrevLockedRef.current ) {
      logOpenPaywall('day_change')
      navigate(`${paths.paywall.path}`, {state: {backgroundLocation: locationRef.current}})
      return
    }
    logChangeOverview(rangeRef.current, chartRef.current, sliceRef.current?.type)
    logDayChange(rangeRef.current, chartRef.current, sliceRef.current?.type)
    switch (rangeRef.current) {
      case 'day':
        onChangeRef.current(subDays(valueRef.current, 1))
        break
      case 'week':
        onChangeRef.current(subWeeks(valueRef.current, 1))
        break
      case 'month':
        onChangeRef.current(subMonths(valueRef.current, 1))
        break
      case 'year':
        onChangeRef.current(subYears(valueRef.current, 1))
        break
    }
  }


  const renderTitle = () => {
    switch (rangeRef.current) {
      case 'day':
        return (
          <Flex>
            <Text textStyle={'lg'}>{format(valueRef.current, 'dd MMM, yyyy')}</Text>
            {isToday(valueRef.current) && (
              <Text color={'white.3'} ml={'8px'} textStyle={'lg'}>
                {t('common.today')}
              </Text>
            )}
          </Flex>
        )
      case 'week':
        return (
          <Flex>
            <Text textStyle={'lg'}>
              {format(valueRef.current, 'dd MMM')} -{' '}
              {format(endOfWeek(valueRef.current, {weekStartsOn: 1}), 'dd MMM yyyy')}
            </Text>
          </Flex>
        )
      case 'month':
        return (
          <Flex>
            <Text textStyle={'lg'}>{format(valueRef.current, 'MMMM')}</Text>
          </Flex>
        )
      case 'year':
        return (
          <Flex>
            <Text textStyle={'lg'}>{format(valueRef.current, 'yyyy')}</Text>
          </Flex>
        )
      default:
        return null
    }
  }

  const renderShowTodayButton = () => {
    if (isCurrentPeriod(valueRef.current, rangeRef.current)) {
      return null
    }
    return (
      <Button ml={'8px'} onClick={onToday} p={'2px'} color={'accent.1'} variant={'ghost'}>
        {t('common.goToToday')}
      </Button>
    )
  }

  const onArrowPress = (event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
      onPrevious()
      return
    }
    if (event.key === 'ArrowRight') {
      onNext()
      return
    }
  }

  useEffect(() => {
    locationRef.current = location
  }, [location])

  useEffect(() => {
    document.addEventListener('keyup', onArrowPress, false)
    return () => {
      document.removeEventListener('keyup', onArrowPress, false)
    }
  }, [])

  useEffect(() => {
    if (searchParams.has('day')) {
      let date = new Date()
      try {
        date = parse(searchParams.get('day') || '', 'yyyy-MM-dd', new Date())
      } catch (err) {}
      onDayFromParam(date)
      searchParams.delete('day')
      setSearchParams(searchParams)
    }
  }, [searchParams])

  useEffect(() => {
    switch (rangeRef.current) {
      case 'day':
        if (!isFullAccess && differenceInCalendarDays(new Date(), valueRef.current) > freeDaysPeriod) {
          onRedirectToPaywall()
        }
        break
      case 'week':
        if (!isFullAccess && differenceInCalendarDays(new Date(), endOfWeek(valueRef.current, {weekStartsOn: 1})) > freeDaysPeriod) {
          onRedirectToPaywall()
        }
        break
      case 'month':
        if (!isFullAccess && differenceInCalendarDays(new Date(), endOfMonth(valueRef.current)) > freeDaysPeriod) {
          onRedirectToPaywall()
        }
        break
      case 'year':
        if (!isFullAccess && differenceInCalendarDays(new Date(), endOfYear(valueRef.current)) > freeDaysPeriod) {
          onRedirectToPaywall()
        }
        break
    }
  }, [valueRef.current])

  return (
    <Flex justifyContent={'flex-start'} w={'100%'} alignItems={'center'}>
      <Flex mr={'12px'} position={'relative'}>
        <Box>
          <IconButton icon={'leftArrow'} variant={'ghost'} onClick={onPrevious} aria-label={'previous-range'}/>
          {isPrevLockedRef.current  && <Box position={'absolute'} left={'25px'} bottom={'25px'}><Icon color={'white.3'} type={'lock'} width={16} height={16} /></Box>}
        </Box>
        <IconButton
          isDisabled={isCurrentPeriod(valueRef.current, rangeRef.current)}
          icon={'rightArrow'}
          variant={'ghost'}
          onClick={onNext}
          aria-label={'next-range'}
        />
      </Flex>
      {renderTitle()}
      {renderShowTodayButton()}
    </Flex>
  )
}
