import {FC, useEffect, useRef} from 'react'
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom'
import {Grid, GridItem} from '@chakra-ui/react'
import NavBar from './components/NavBar/NavBar'
import {paths} from 'shared/constants/paths'

const DashboardRouter: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {id} = useParams()

  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (location.pathname === paths.dashboard.path) {
      navigate(paths.overview.path, {replace: true})
    }
  }, [location])

  useEffect(() => {
    if (id) {
      containerRef.current?.scrollTo(0, 0)
      const root = document.getElementById('root') as HTMLElement
      root.classList.add('highlight')
    } else {
      const root = document.getElementById('root') as HTMLElement
      root.classList.remove('highlight')
    }
  }, [id])

  return (
    <Grid
      templateAreas={`"navbar" 
                      "main"`}
      gridTemplateRows={'auto 1fr'}
      gridTemplateColumns={'auto'}
      h="100vh"
      fontWeight="bold">
      <GridItem area={'navbar'}>
        <NavBar />
      </GridItem>
      <GridItem area={'main'} overflowY={'scroll'} ref={containerRef}>
        <Outlet />
      </GridItem>
    </Grid>
  )
}

export default DashboardRouter
