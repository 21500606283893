import React, {FC, useEffect} from 'react'
import {Flex, Text} from '@chakra-ui/react'
import {logFeaturePopularity} from 'services/analytics/analytics'

interface IProps {}

const FriendsScene: FC<IProps> = ({}) => {
  useEffect(() => {
    logFeaturePopularity('friends')
  }, [])
  return (
    <Flex w={'100%'} h={'100%'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap={'30px'}>
      <Text textStyle={'xl'}>Coming soon</Text>
    </Flex>
  )
}

export default FriendsScene
