import { AxiosInstance } from "axios";
import {useMutation, useQuery} from '@tanstack/react-query'
import {projectsKeys} from './projects.keys';
import {getProject, getProjects, saveProject} from './projects.api'
import {IProject} from '../../types'

export const useProjectsQuery = (
  options: any,
  api: AxiosInstance,
) => {
  return useQuery(projectsKeys.all(), () => getProjects(api), options);
};

export const useProjectQuery = (
  id: string | undefined,
  options: any,
  api: AxiosInstance,
) => {
  return useQuery(projectsKeys.byId(id), () => getProject(id!, api), {...options, enabled: !!id});
};


export const useSaveProjectMutation = (
  options: any,
  api: AxiosInstance
) => {
  return useMutation<IProject[], any, IProject>((data: IProject) => saveProject(data, api), options)
};