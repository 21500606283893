import {FC, useEffect, useState} from 'react'
import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputRightElement,
  Link,
  Text
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {Button, IconButton, Input} from 'components'
import {useForm} from 'react-hook-form'
import {emailRegex} from '@kaef/common/utils/helpers'
import {browserLocalPersistence, createUserWithEmailAndPassword, setPersistence} from 'firebase/auth'
import {auth} from 'services/firebase/firebase'
import {useAuthStore} from '@kaef/common/stores'
import {paths} from 'shared/constants/paths'
import {useLocation, Link as DomLink} from 'react-router-dom'
import {generatePathFromLocation} from 'shared/utils/navigation.helper'

interface IProps {
  navToSignIn: () => void
  navToWelcome: () => void
  navToOnboarding: () => void
}

interface ISignUpForm {
  email: string
  password: string
}

const SignUpScene: FC<IProps> = ({navToSignIn, navToWelcome}) => {
  const {t} = useTranslation()
  const location = useLocation()
  const [setAfterAuthRedirect] = useAuthStore((state) => [state.setAfterAuthRedirect])

  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [emailError, setEmailError] = useState<string | null>(null)

  const {watch, formState, register, handleSubmit} = useForm<ISignUpForm>({
    mode: 'onBlur'
  })

  const onSignUp = async (data: ISignUpForm) => {
    if (!isChecked) {
      return
    }
    setIsLoading(true)
    setEmailError(null)
    const {email, password} = data
    try {
      if (location.state?.from) {
        setAfterAuthRedirect(generatePathFromLocation(location.state.from))
      } else {
        setAfterAuthRedirect(paths.dashboard.path)
      }
      await setPersistence(auth, browserLocalPersistence)
      await createUserWithEmailAndPassword(auth, email, password)
    } catch (error) {
      // @ts-ignore
      if (error.code === 'auth/email-already-in-use') {
        setEmailError(t('authorization.errors.emailInUse') as string)
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (emailError) {
      setEmailError(null)
    }
  }, [watch('email')])

  const isEmailError = !!formState.errors?.email || emailError
  const isPasswordError = !!formState.errors?.password

  return (
    <>
      <IconButton
        top={'-20px'}
        left={'-10px'}
        icon={'arrowBack'}
        variant={'ghost'}
        onClick={navToWelcome}
        aria-label={'navToWelcome'}
      />
      <Text mb={'8px'} textStyle={'lg'} color={'white.1'}>
        {t('authorization.signUp.title')}
      </Text>
      <Text mb={'32px'} textStyle={'md'} color={'white.3'}>
        {t('authorization.signUp.subtitle')}
      </Text>

      <FormControl isInvalid={!!isEmailError}>
        <FormLabel mb={'8px'} textStyle={'lg'} color={'white.1'}>
          {t('authorization.signUp.email')}
        </FormLabel>
        <Input
          isInvalid={!!isEmailError}
          {...register('email', {required: t('authorization.errors.emailRequired') as string, pattern: emailRegex})}
          type={'email'}
          placeholder={String(t('authorization.signUp.emailPlaceholder'))}
          size="md"
        />
        {isEmailError && <FormErrorMessage>{emailError}</FormErrorMessage>}
      </FormControl>

      <FormControl mb={'20px'} isInvalid={isPasswordError}>
        <FormLabel mt={'32px'} mb={'8px'} textStyle={'lg'} color={'white.1'}>
          {t('authorization.signUp.password')}
        </FormLabel>
        <InputGroup size="md">
          <Input
            isInvalid={isPasswordError}
            {...register('password', {
              required: t('authorization.errors.passwordRequired') as string,
              minLength: {value: 6, message: t('authorization.errors.passwordMinLength') as string}
            })}
            type={isShow ? 'text' : 'password'}
            placeholder={String(t('authorization.signUp.passwordPlaceholder'))}
            size="md"
          />
          <InputRightElement>
            <IconButton
              icon={isShow ? 'eyeHide' : 'eyeShow'}
              variant={'ghost'}
              onClick={() => setIsShow(!isShow)}
              aria-label={'showPass'}
            />
          </InputRightElement>
        </InputGroup>
        {isPasswordError && <FormErrorMessage>{formState.errors?.password?.message}</FormErrorMessage>}
      </FormControl>
      <Checkbox size="lg" defaultChecked mb={'20px'} isChecked={isChecked} onChange={() => setIsChecked(!isChecked)}>
        <Text textStyle={'md'} color={'white.3'}>
          I agree with{' '}
          <Link color={'accent.1'} as={DomLink} to={paths.terms.path} target={'_blank'}>
            terms and conditions
          </Link>{' '}
          and{' '}
          <Link color={'accent.1'} as={DomLink} to={paths.terms.path} target={'_blank'}>
            privacy policy
          </Link>
        </Text>
      </Checkbox>
      <Button isLoading={isLoading} mb={'26px'} onClick={handleSubmit(onSignUp)} variant={'accent'}>
        {t('authorization.signUp.signUp')}
      </Button>

      <Text textStyle={'md'} color="white.3">
        {t('authorization.signUp.signInTitle')}&nbsp;
        <Link onClick={navToSignIn} color="white.2">
          {t('authorization.signUp.signIn')}
        </Link>
      </Text>
    </>
  )
}

export default SignUpScene
