import {IRouteDescriptor} from 'shared/models/route-descriptor.interface'

type Path =
  | 'default'
  | 'linkPlugin'
  | 'home'
  | 'dashboard'
  | 'overview'
  | 'paywallSuccess'
  | 'paywallFail'
  | 'trends'
  | 'notifications'
  | 'statistics'
  | 'goals'
  | 'authorization'
  | 'onboarding'
  | 'signIn'
  | 'plugins'
  | 'linkPluginConfirm'
  | 'paywall'
  | 'subscription'
  | 'signUp'
  | 'forgotPassword'
  | 'editGoal'
  | 'editProject'
  | 'profile'
  | 'instructions'
  | 'projects'
  | 'terms'
  | 'privacy'
  | 'resetPassword'
  | 'friends'
  | 'share'

export const paths: Record<Path, IRouteDescriptor> = {
  default: {
    path: '/*'
  },
  home: {
    path: '/'
  },

  terms: {
    path: '/terms'
  },

  privacy: {
    path: '/privacy'
  },

  //Link plugin
  linkPlugin: {
    path: '/link/:id'
  },

  linkPluginConfirm: {
    path: '/link/confirm'
  },

  //Authorization section
  authorization: {
    path: '/authorization',
    isModal: true,
    backgroundPath: '/'
  },
  signIn: {
    path: '/authorization/signIn',
    isModal: true,
    backgroundPath: '/'
  },
  signUp: {
    path: '/authorization/signUp',
    isModal: true,
    backgroundPath: '/'
  },
  forgotPassword: {
    path: '/authorization/forgotPassword',
    isModal: true,
    backgroundPath: '/'
  },
  resetPassword: {
    path: '/authorization/resetPassword',
    isModal: true,
    backgroundPath: '/'
  },

  //Onboarding section
  onboarding: {
    path: '/dashboard/onboarding',
    isModal: true,
    private: true,
    backgroundPath: '/dashboard/overview/day'
  },

  //Dashboard section
  dashboard: {
    path: '/dashboard',
    private: true
  },
  overview: {
    path: '/dashboard/overview',
    private: true
  },
  projects: {
    path: '/dashboard/projects',
    private: true
  },
  plugins: {
    path: '/dashboard/plugins',
    private: true
  },
  statistics: {
    path: '/dashboard/statistics',
    private: true
  },
  goals: {
    path: '/dashboard/goals',
    private: true
  },
  editGoal: {
    path: '/dashboard/goals/edit',
    private: true,
    isModal: true,
    backgroundPath: '/dashboard/overview/day'
  },
  editProject: {
    path: '/dashboard/project/edit',
    private: true,
    isModal: true,
    backgroundPath: '/dashboard/overview/day',
    relPath: '/dashboard/project/edit/:id'
  },
  trends: {
    path: '/dashboard/trends',
    private: true
  },
  friends: {
    path: '/dashboard/friends',
    private: true
  },

  // Profile section
  profile: {
    path: '/dashboard/profile',
    isModal: true,
    private: true,
    backgroundPath: '/dashboard/overview/day'
  },
  instructions: {
    path: '/dashboard/instructions',
    isModal: true,
    private: true,
    backgroundPath: '/dashboard/overview/day',
    relPath: '/dashboard/instructions/:id'
  },
  paywall: {
    path: '/dashboard/paywall',
    isModal: true,
    private: true,
    backgroundPath: '/dashboard/overview/day'
  },
  paywallSuccess: {
    path: '/dashboard/paywall/success',
    isModal: true,
    private: true,
    backgroundPath: '/dashboard/overview/day'
  },
  paywallFail: {
    path: '/dashboard/paywall/fail',
    isModal: true,
    private: true,
    backgroundPath: '/dashboard/overview/day'
  },
  subscription: {
    path: '/dashboard/subscription',
    isModal: true,
    private: true,
    backgroundPath: '/dashboard/overview/day'
  },
  notifications: {
    path: '/dashboard/notifications',
    isModal: true,
    private: true,
    backgroundPath: '/dashboard/overview/day',
    relPath: '/dashboard/notifications/:id'
  },
  share: {
    path: '/dashboard/share',
    isModal: true,
    private: true,
    backgroundPath: '/dashboard/overview/day'
  }
}
