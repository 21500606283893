export const en = {
  translation: {
    linkPlugin: {
      confirmTitle:
        'You are going to link the {{plugin}} plugin to your account',
      cancel: 'Cancel',
      connect: 'Connect',
      successTitle: 'Plugin linked!',
      successDescription: 'You will start seeing your statistic soon',
      error: {
        auth: 'Authorize first',
        pluginId: 'Plugin id not found',
        server: 'Server error while linking'
      }
    },
    authorization: {
      errors: {
        emailRequired: 'Email required',
        emailInUse: 'Email already in use',
        passwordRequired: 'Password required',
        passwordMinLength: 'At least 6 characters',
        incorrectPassword: 'Incorrect email or password',
        passwordMissmatch: 'Passwords do not match'
      },
      welcome: {
        title: 'Welcome to Nau Time Tracker',
        subtitle: 'Create the ultimate time tracking experience',
        google: 'Continue with Google',
        facebook: 'Continue with Facebook',
        github: 'Continue with GitHub',
        email: 'Continue with Email',
        apple: 'Continue with Apple',
        authError: {
          title: 'Authorization error',
          description:
            'You already registered with this email. Please try sign in with another provider.'
        }
      },
      signIn: {
        title: 'Welcome back',
        subtitle: 'Glad to see you again',
        email: 'Email address',
        emailPlaceholder: 'Email',
        password: 'Password',
        passwordPlaceholder: 'Password',
        forgotPassword: 'Forgot password',
        signIn: 'Sign In',
        signUpTitle: 'Don’t have an account?',
        signUp: 'Sign Up'
      },
      signUp: {
        title: 'Welcome to Nau Time Tracker',
        subtitle: 'Create the ultimate time tracking experience',
        email: 'Email address',
        emailPlaceholder: 'Email',
        password: 'Create password',
        passwordPlaceholder: 'Password',
        signUp: 'Sign Up',
        signInTitle: 'Already have an account?',
        signIn: 'Sign In'
      },
      forgotPassword: {
        title: 'Forgot your password?',
        subtitle:
          'Enter your email address below - we’ll send you a link to reset it',
        email: 'Email address',
        emailPlaceholder: 'Email',
        send: 'Send link',
        sent: 'Link was sent to your email'
      },
      resetPassword: {
        title: 'Reset password',
        subtitle: 'Enter your new password for {{value}}',
        password: 'New password',
        passwordPlaceholder: 'Password',
        confirmPassword: 'Confirm new password',
        confirmPasswordPlaceholder: 'Password',
        save: 'Save'
      }
    },
    landing: {
      header: 'Boost Your Productivity',
      headerDesc:
        'Nau is a modern automatic time tracker. Featuring a modern web UI and a mobile app, to help you develop good work habits and achieve success',
      joinButton: 'Join For Free',
      home: {
        title: 'Home'
      },
      privacy: {
        title: 'Security',
        subtitle: 'Privacy first',
        disclaimer:
          'We do not store, transfer, or have access to your code. The only information we retain is project names, file names, and branch names. All collected statistics are private and available only to you.'
      },
      plugins: {
        title: 'Available plugins',
        subtitle: 'Easy to track with our plugins'

      },
      roadmap: {
        title: 'Roadmap',
        subtitle: 'What’s coming next',
        done: 'Done',
        comingSoon: 'Coming soon',
        wearehere: 'We are here',
        list: {
          wakatime: 'Import from Wakatime',
          activityTracker: 'Activity tracker',
          goals: 'Goals',
          jetbrains: 'JetBrains plugins',
          vscode: 'VS Code plugin',
          mobile: 'Mobile app',
          leaderboards: 'Leaderboards with friends',
          publicProfile: 'Public profile',
          teams: 'Teams project and dashboard',
          browser: 'Browser plugin',
          desktop: 'Desktop plugin',
          watch: 'Watch app'
        }
      },
      pricing: {
        title: 'Pricing',
        getStarted: 'Get started',
        subtitle:
          'Discover the Cost of Boosting Your Productivity to the Next Level'
      },
      features: {
        title: 'Features',
        subtitle: 'Time Tracker Of The Future',
        first: {
          heading: 'Deep Dive Into Work Statistics',
          description:
            'We track the time spent on specific app, websites, projects, branches, and even files'
        },
        second: {
          heading: 'Set Goals For Yourself',
          description:
            'Improve a bit every day, enhance work performance, and your life will soon get better'
        },
        third: {
          heading: 'Cross-Platform Solutions',
          description:
            'We offer plugins for JetBrains products and VS Code. Soon, we\'ll also launch a desktop apps for Win/Mac, along with plugins for Chrome'
        }
      },
      signIn: 'Sign In',
      footer: {
        website: 'WEBSITE',
        connectWithUs: 'CONNECT WITH US',
        discord: 'Discord',
        email: 'Email',
        twitter: 'Twitter',
        github: 'Github',
        legal: 'LEGAL',
        privacyPolicy: 'Privacy Policy',
        termsOfService: 'Terms of Service'
      }
    },
    onboarding: {
      name: {
        title: 'What is your name?',
        subtitle: 'Actually, you can stay anonymous',
        placeholder: 'Your name',
        next: 'Next',
        skip: 'Skip',
        later: 'Specify later',
        value: 'Hello, {{value}}!'
      },
      start: {
        title: 'What time would you like to start working?',
        subtitleRecommend:
          'You really like to sleep longer! We recommend you to set {{value}}',
        setRecommend: 'Set recommend value',
        sliderLabel: 'Start time',
        next: 'Next',
        skip: 'Specify later'
      },
      finish: {
        title: 'What time would you like to finish working?',
        subtitleRecommend:
          'It\'s better to finish working not so late. We recommend you to set {{value}}',
        setRecommend: 'Set recommend value',
        sliderLabel: 'Finish time',
        next: 'Next',
        skip: 'Specify later'
      },
      duration: {
        title: 'How much time are you planning to code per day?',
        subtitleRecommend:
          'It doesn\'t seem to have a very good effect on your health! We recommend  you to set {{value}}',
        setRecommend: 'Set recommend value',
        sliderLabel: 'Coding time per day',
        next: 'Next',
        skip: 'Specify later',
        hoursAmount: '{{value}}hrs'
      },
      days: {
        title: 'Which days are your working days?',
        subtitleRecommend:
          'Free schedule is right about you. We recommend work from Mon to Fri. It should be ok',
        setRecommend: 'Set recommend value',
        next: 'Next',
        skip: 'Specify later'
      },
      setup: {
        title: 'Let\'s install the plugin to start getting the first statistics',
        next: 'Next'
      },
      import: {
        title:
          'If you are using Wakatime plugin as a time tracking tool, you can import your data into our app',
        subtitle: 'You can do it later in Plugins tab',
        next: 'Done'
      }
    },
    common: {
      pageTitle: 'Nau Time',
      today: 'Today',
      goToToday: 'Go to today',
      validation: {
        emailInvalid: 'Email address is invalid',
        lengthInvalid: 'At least {{value}} characters'
      },
      calendar: {
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat',
        sun: 'Sun'
      }
    },
    menu: {
      overview: 'Overview',
      statistics: 'Statistics',
      goals: 'Goals',
      projects: 'Projects',
      plugins: 'Plugins',
      trends: 'Trends',
      friends: 'Friends'
    },
    overview: {
      noPlugin: 'You don\'t have any plugins connected',
      connect: 'Connect plugin',
      noActivityTitle: 'There is no activity here',
      noActivityDescription:
        'The plugin did not register any activity, it seems you did not work during this period of time',

      ranges: {
        d: 'Day',
        w: 'Week',
        m: 'Month',
        y: 'Year'
      },
      day: {
        total: 'Total',
        goalStart: 'Start goal',
        goalFinish: 'Finish goal'
      },
      week: {
        total: 'Total',
        avg: 'Average'
      },
      month: {
        total: 'Total',
        avg: 'Average'
      },
      year: {
        total: 'Total',
        avgMonth: 'Average per month',
        avgDays: 'Average per day'
      },
      pieChart: {
        title_one: '{{count}} item',
        title_other: '{{count}} items'
      }
    },
    sidebar: {
      weekPlan: {
        title: 'Working week',
        type: {
          DAY_OFF: 'Day-off',
          WORK: 'Working day',
          WEEKEND: 'Weekend',
          SICK: 'Sick leave',
          VACATION: 'Vacation',
          HOLIDAY: 'Holiday'
        }
      },
      todayPlan: {
        wellDone: 'Well done!',
        leftWork: 'left to work today',
        goodPace: 'Good pace, keep it up!',
        myPlans: 'My plans for today',
        startGoal: 'Start working no later than {value}',
        durationGoal: 'Work at least {value} hours',
        endGoal: 'Finish working no later than {value}'
      },
      todayGoals: {
        title: 'Goals for {{value}}',
        today: 'Today',
        yesterday: 'Yesterday',
        daysCompleted: '{{value}} out of {{total}} days is completed',
        duration: {
          title: 'Total',
          averageTotal: 'Average total',
          workAtLeast: 'Work at least {{value}}',
          plannedWorkTime: '{{value}}% of the planned working time',
          noGoal: 'There is no goal for the duration'
        },
        start: {
          title: 'Started',
          averageStart: 'Average start',
          startWorkGoal: 'Start work no later than {{value}}',
          startWorkEarlier: 'Started work {{value}} earlier than planned',
          startWorkLater: 'Started work {{value}} later than planned',
          startWorkExact: 'Started work exactly as planned',
          notStarted: 'Not started yet',
          noGoal: 'There is no goal for start time'
        },
        end: {
          title: 'Finished',
          averageFinish: 'Average finish',
          finishWorkGoal: 'Finish work no later than {{value}}',
          finishWorkEarlier: 'Finished work {{value}} earlier than planned',
          finishWorkLater: 'Finished work {{value}} later than planned',
          finishWorkExact: 'Finished work exactly as planned',
          notFinished: 'Finish work before {{value}} to complete this goal',
          noGoal: 'There is no goal for finish time'
        },

        goals: 'goals',
        longestStreak: '{{value}} days longest streak',

        editGoals: {
          save: 'Save goal',
          cancel: 'Cancel'
        }
      }
    },
    navBar: {
      getPlugin: 'Get JetBrains plugin',
      installPlugin: 'Install plugin',
      signOut: 'Sign out',
      upgradeToPro: 'Upgrade to Pro',
      proIsActive: 'Pro power is active'
    },
    jetBrains: {
      title: 'Let\'s install the plugin to start getting the first statistics',
      subtitle: 'You can download Nau plugin from a trusted marketplace',
      stepOne: '1. Open Settings and navigate to Plugin sections',
      stepTwo: '2. Type Nau in search input',
      stepThree: '3. Press Install',
      stepFour:
        '4. Press Link plugin action in a popup, it will appear in a few moments after plugin\'s install',
      stepFourExtra:
        'Don\'t worry if you miss a popup, you can click on Nau plugin icon in a tray in right bottom corner (if you don\'t see it, please restart IDE)',
      stepFive:
        '5. You will be redirected to our site and we automatically link your Nau plugin with your account',
      stepSix:
        '6. Done! When you return back to IDE you will see popup with congratulations',
      stepSixExtra: 'If you have any problems, you can reach us via email '
    },
    wakatimeImport: {
      title: 'You can import your Wakatime data in our app',
      subtitle: '',
      import: 'Import',
      toastTitle: 'Import started',
      toastDescription:
        'You will receive a notification when the import is done',
      toastErrorTitle: 'Something went wrong',
      toastErrorDescription: 'Please try again later or contact support'
    },
    project: {
      editModal: {
        title: 'Edit project',
        cancel: 'Cancel',
        save: 'Save',
        placeholder: 'Project name',
        error: 'Name can\'t be empty'
      }
    },
    projects: {
      title: 'Projects',
      hidden: `Hidden projects ({{value}})`,
      empty: 'No projects detected yet'
    },
    plugins: {
      title: 'Plugins'
    },
    profile: {
      name: 'Name',
      email: 'Email',
      password: 'Password',
      timezone: 'Timezone',
      language: 'Language',
      deleteMyAccount: 'Delete my account',
      cancel: 'Cancel',
      save: 'Save'
    },
    paywall: {
      currentPlan: 'Current plan',
      title: 'Unlock Premium Power Nau',
      subtitle: 'Get the most out of life with Pro Nau',
      upgrade: 'Upgrade',
      nextPlan: 'After trial'
    },
    notifications: {
      title: 'Notifications Center',
      markAll: 'Mark all as read',
      close: 'Close',
      empty: 'No notifications yet'
    },
    share: {
      share: 'Share',
      title: 'Share my statistics for {{value}}',
      shareIn: 'Share in {{value}}'
    },
    subscription: {
      upgrade: 'Upgrade',
      currentPlan: 'Current plan',
      subscribed: 'Subscribed',
      cancel: 'Cancel',
      title: 'Pro power is active ',
      subtitle: 'Enjoy all the benefits of the Pro version',
      getStarted: 'Get Started',
      unknownErrorTitle: 'Something went wrong',
      unknownError: 'Please contact support or try again later',
      daysLeft_one: '{{count}} day left',
      daysLeft_other: '{{count}} days left',
      trialDaysLeft_one: '{{count}} day trial left',
      trialDaysLeft_other: '{{count}} days trial left',
      free: {
        title: 'Free Plan',
        subtitle: 'Start Your Productivity Journey Today',
        feature1: 'Save stats forever',
        feature2: 'View up to 2 weeks of history',
        feature3: 'Set personal goals'
      },
      month: {
        title: 'Monthly',
        subtitle: 'Unlock Advanced Tools and Insights',
        badge: 'Most popular',
        period: 'per month',
        feature1: 'Save stats forever',
        feature2: 'View all history',
        feature3: 'Set personal goals'
      },
      year: {
        title: 'Yearly',
        subtitle: 'Unlock Advanced Tools and Insights',
        period: 'per year',
        badge: 'Save 20%',
        feature1: 'Save stats forever',
        feature2: 'View all history',
        feature3: 'Set personal goals'
      },
      trial: {
        title: 'Trial',
        subtitle: 'Unlock Advanced Tools and Insights',
        badge: 'Free',
        period: '1 month',
        feature1: 'Save stats forever',
        feature2: 'View all history',
        feature3: 'Set personal goals'
      }
    }
  }
}
