import {Center, CircularProgress as ChakraCircularProgress, Flex, Image, Skeleton, Text} from '@chakra-ui/react'
import {FC, useEffect} from 'react'

import {useDaySliceStore, useOverviewStore} from '@kaef/common/stores'

import {endOfDay, format, startOfDay} from 'date-fns'
import {Button, ButtonGroup, IconButton, Stat} from 'components'
import {CircularProgress} from 'components/Charts/CircularProgress/CircularProgres'
import {api} from 'services/api'
import {goalSelector} from '@kaef/common/api/goals/goals.selector'
import {DayChart} from './components/DayChart'
import Monitor from 'assets/monitor.png'
import {useTranslation} from 'react-i18next'
import {secondaryGroupContainerProps} from '../utils'
import {
  colorFuse,
  parseISOToUTC,
  prepareOverviewRequestData,
  secondsToString
} from '@kaef/common/utils/helpers'
import {
  logChangeOverview,
  logChangeSlice,
  logOpenChart, logOpenShareModal,
  logProjectOpenDetails,
  logProjectToggleVisibility
} from 'services/analytics/analytics'

import {PieChart} from 'components/Charts/Echarts/PieChart'
import {ERange, EShareType, ISliceData} from '@kaef/common/types'
import {useInitFromUrlParams} from 'shared/hooks/useInitFromUrlParams'
import {paths} from 'shared/constants/paths'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {overviewKeys} from '@kaef/common/api/overview/overview.keys'
import {projectsKeys} from '@kaef/common/api/projects/projects.keys'
import {useQueryClient} from '@tanstack/react-query'

const generatePieData = (slices: ISliceData[] = [], total: number = 0) => {
  if (!slices || !total) {
    return []
  }
  const chartData = slices.map((item) => ({
    value: item.percent,
    valueSeconds: item.seconds,
    color: colorFuse(item.color),
    name: item.name
  }))
  chartData.sort((a, b) => b.value - a.value)
  return chartData
}

const DayLayout: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
  const {id} = useParams()

  const {t} = useTranslation()
  const day = useOverviewStore((state) => state.day)
  const [slice, setSlice, chart, setChart] = useDaySliceStore((state) => [state.slice, state.setSlice, state.chart, state.setChart])

  const overviewDayQuery = api.useOverviewDayQuery(prepareOverviewRequestData(day, ERange.DAY, id))
  const goalQuery = api.useGoalsQuery()
  const projectsQuery = api.useProjectsQuery()
  const saveProjectMutation = api.useSaveProjectMutation()
  const userQuery = api.useUserInfoQuery()
  const shareMutation = api.useShareToTwitterMutation()

  const sliceKey = useInitFromUrlParams(ERange.DAY, setChart)

  const onChangeSlice = (value: string) => {
    logChangeSlice(value, 'day')
    logChangeOverview('day', chart, value)
    const findSlice = overviewDayQuery.data?.slices.find((slice) => slice.type === value)
    if (findSlice) {
      setSlice(findSlice)
    }
  }

  const onOpenDayTotal = () => {
    setChart('DAY_TOTAL')
    logChangeOverview('day', 'DAY_TOTAL', slice?.type)
    logOpenChart('DAY_TOTAL')
  }

  const onOpenDayDetailed = () => {
    setChart('DAY_DETAILED')
    logChangeOverview('day', 'DAY_DETAILED', slice?.type)
    logOpenChart('DAY_DETAILED')
  }

  const onEditProject = (data: ISliceData) => {
    navigate(`${paths.editProject.path}/${data.uuid}`, {state: {backgroundLocation: location}})
  }

  const onToggleVisibility = (data: ISliceData) => {
    if (!projectsQuery.data) {
      return
    }
    const findProject = projectsQuery.data.find((item) => item.id === data.uuid)
    if (findProject) {
      logProjectToggleVisibility()
      saveProjectMutation.mutate(
        {
          ...findProject,
          show: !findProject.show
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(overviewKeys.all())
            queryClient.invalidateQueries(projectsKeys.all())
          }
        }
      )
    }
  }

  const onNavToSliceDetails = (data: ISliceData) => {
    logProjectOpenDetails()
    navigate(`${paths.overview.path}/day/${data.uuid}`)
  }

  const currentSlice = overviewDayQuery.data?.slices?.find((_slice) => _slice.type === slice?.type)

  const isLoading = overviewDayQuery.isLoading || goalQuery.isLoading
  const isError = overviewDayQuery.isError || goalQuery.isError
  const isNoPlugins = !isLoading && userQuery.data?.plugins.length === 0

  const slices = overviewDayQuery.data?.slices?.map((slice) => ({
    label: slice.title,
    value: slice.type
  }))

  const {total} = overviewDayQuery.data || {total: 0, average: 0}

  const start = currentSlice ? parseISOToUTC(currentSlice.start) : startOfDay(day)
  const end = currentSlice ? parseISOToUTC(currentSlice.end) : endOfDay(day)

  const goalDuration = goalSelector('DURATION')(goalQuery.data)
  const goalStart = goalSelector('START')(goalQuery.data)
  const goalEnd = goalSelector('END')(goalQuery.data)

  const onShare = () => {
    navigate(`${paths.share.path}?date=${format(day, 'yyyy-MM-dd')}&type=${EShareType.DAY}${!id ? '' : `&projectUid=${id}`}`, {state: {backgroundLocation: location}})
    logOpenShareModal('day', !!id, chart, slice?.type)
  }

  useEffect(() => {
    if (!overviewDayQuery.data?.slices) {
      return
    }
    if (slice && overviewDayQuery.data?.slices.find((item) => item.type === slice.type)) {
      return
    }
    if (!sliceKey) {
      setSlice(overviewDayQuery.data?.slices[0])
      return
    }
    const findSlice = overviewDayQuery.data?.slices.find((slice) => slice.type === sliceKey)
    if (findSlice) {
      setSlice(findSlice)
    } else {
      setSlice(overviewDayQuery.data?.slices[0])
    }
  }, [overviewDayQuery.data])

  useEffect(() => {
    if (id) {
      setChart('DAY_DETAILED')
    }
  }, [id])

  return (
    <Flex w={'100%'} flexDirection={'column'}>
      {isError && <Flex>Request Error</Flex>}
      {!isError && isNoPlugins && (
        <Flex alignItems={'center'} flexDirection={'column'}>
          <Image w={'210px'} mt={'10px'} mb={'10px'} src={Monitor} />
          <Text mt={'20px'} color={'white.1'} textStyle={'md'}>
            {t('overview.noPlugin')}
          </Text>
          <Button mt={'20px'} w={'200px'} variant={'accent'}
                  onClick={() => navigate(paths.plugins.path)}>{t('overview.connect')}</Button>
        </Flex>
      )}
      {!isError && !isNoPlugins && (
        <>
          <Flex mt={'30px'} mb={'40px'} w={'100%'} justifyContent={'center'} alignItems={'center'}>
            {isLoading ? (
              <Center pb={'42px'} h={'347px'} w={'100%'}>
                <ChakraCircularProgress isIndeterminate color={'accent.1'} />
              </Center>
            ) : (
              <Flex
                w={'100%'}
                flexDirection={['column-reverse', 'column-reverse', 'row']}
                alignItems={['center', 'center', 'flex-start']}
                justifyContent={'center'}
                gap={[0, 0, '40px']}>
                <Flex mt={'15px'} justifyContent={'center'} width={'220px'}>
                  {chart === 'DAY_TOTAL' && (
                    <CircularProgress goalTime={goalDuration?.value} spentTime={overviewDayQuery.data?.total} />
                  )}
                  {chart === 'DAY_DETAILED' && (
                    <PieChart height={220} data={generatePieData(currentSlice?.data, goalDuration?.value)} />
                  )}
                </Flex>
                <Flex
                  flexDirection={['row', 'row', 'column']}
                  justifyContent={['space-between', 'space-between', 'flex-start']}
                  w={['100%', '100%', '160px']}
                  flexShrink={0}
                  mt={'16px'}>
                  <Flex mb={'40px'}>
                    {!id && (
                      <IconButton
                        minWidth={'32px'}
                        w={'32px'}
                        h={'32px'}
                        mr={'6px'}
                        icon={'time1'}
                        variant={chart === 'DAY_TOTAL' ? 'accent' : 'ghost'}
                        onClick={onOpenDayTotal}
                        aria-label={'day-total'}
                      />
                    )}
                    <IconButton
                      minWidth={'32px'}
                      w={'32px'}
                      h={'32px'}
                      icon={'pieChart'}
                      variant={chart === 'DAY_DETAILED' ? 'accent' : 'ghost'}
                      onClick={onOpenDayDetailed}
                      aria-label={'day-detailed'}
                    />
                  </Flex>
                  <Flex flexDirection={['row', 'row', 'column']} gap={'12px'}>
                    <Stat
                      value={secondsToString(Math.round(total))}
                      title={t('overview.day.total')}
                    />
                    <Button isLoading={shareMutation.isLoading} h={'28px'} w={'90px'} size={'sm'} variant={'withIcon'}
                            iconProps={{width: 18, height: 18}}
                            iconPosition={'left'}
                            icon={'share'}
                            onClick={onShare}>
                      <Text textStyle={'md'} ml={'4px'}>Share</Text>
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Flex>
          <Flex justifyContent={'center'}>
            {isLoading ? (
              <Skeleton borderRadius={'8px'} w={'100%'} h={'48px'} />
            ) : (
              <ButtonGroup
                containerProps={secondaryGroupContainerProps}
                variant='secondary'
                options={slices}
                value={slice?.type}
                onChange={onChangeSlice}
              />
            )}
          </Flex>
          {!isLoading && !isError && total === 0 && (
            <Flex mt={'40px'} mb={'40px'} w={'100%'} flexDirection={'column'} alignItems={'center'}>
              <Text textStyle={'md'} mb={'4px'}>
                {t('overview.noActivityTitle')}
              </Text>
              <Text textAlign={'center'} maxW={'280px'} textStyle={'sm'} color={'white.3'}>
                {t('overview.noActivityDescription')}
              </Text>
            </Flex>
          )}
          {total !== 0 && (
            <DayChart
              ranges={currentSlice?.ranges}
              day={day}
              rangeStart={start}
              rangeEnd={end}
              goalEnd={goalEnd}
              goalStart={goalStart}
              data={currentSlice?.data}
              isLoading={isLoading}
              onClick={onNavToSliceDetails}
              disabled={currentSlice?.type !== 'PROJECT' || !!id}
              withEmoji={currentSlice?.type === 'PROJECT'}
              withToolkit={currentSlice?.type === 'PROJECT'}
              onToggleVisibility={onToggleVisibility}
              onEditProject={onEditProject}
            />
          )}
        </>
      )}
    </Flex>
  )
}

export default DayLayout
