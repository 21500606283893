import { IDayGranularity, IMap, IMonthInYear, ISliceData } from "../../types";

export const mapSlice = (data?: ISliceData[]) => {
  if (!data) {
    return {};
  }
  const map: IMap<ISliceData> = {};
  data.forEach((item) => {
    map[item.mapId] = item;
  });
  return map;
};

export const findMinMax = (data: IDayGranularity[] | IMonthInYear[] | undefined) => {
  if (!data) {
    return { min: 0, max: 0 };
  }
  let min = 0;
  let max = 0;
  data.forEach((item) => {
    const dayTotal = item.total
    if (dayTotal !== 0 && (min > dayTotal || min === 0)) {
      min = dayTotal;
    }
    if (max < dayTotal) {
      max = dayTotal;
    }
  });
  return {min, max};
};

export const percentFormatter = (percent?: number) => {
  if (percent === undefined) {
    return ''
  }
  if (percent === 100) {
    return '100%'
  }
  return `${percent.toFixed(1)}%`
}
