import React, {FC} from 'react'
import {Link as DomLink, Outlet} from 'react-router-dom'
import {Box, Flex, Grid, GridItem, IconButton, Link, Menu, MenuButton, MenuItem, MenuList} from '@chakra-ui/react'
import {Icon, Logo} from 'components'
import {paths} from '../../shared/constants/paths'

import FooterBlock from './scenes/Home/components/FooterBlock'
import {useTranslation} from 'react-i18next'

const LandingRouter: FC = () => {
  const {t} = useTranslation()

  return (
    <Box>
      <Grid
        templateAreas={`"nav nav"
                  "main main"
                  "footer footer"`}
        gridTemplateRows={'50px 1fr auto'}
        gridTemplateColumns={'150px 1fr'}
        maxW='1075px'
        gridGap={['40px 0', '40px 0', '130px 0']}
        margin={'0 auto'}
        padding={['15px 16px', '15px 16px', '25px']}
        minHeight='100vh'>
        <GridItem area={'nav'} display={'grid'} alignItems={'center'} gridTemplateColumns={'200px 1fr'} width={'100%'}>
          <Logo path={paths.home.path} />
          <Box display={['inline-block', 'inline-block', 'none']} marginLeft={'auto'}>
            <Menu>
              <MenuButton
                border={'none'}
                as={IconButton}
                aria-label='Options'
                icon={<Icon type={'hamburger'} />}
                variant='outline'
              />
              <MenuList bg={'black.1'} width={'90vw'} padding={'0'}>
                <MenuItem padding={'0'} bg={'black.1'} borderRadius={'6px 6px 0 0'}>
                  <Box width={'100%'} padding={'0 12px'}>
                    <Link
                      padding={'10px 0'}
                      display={'block'}
                      borderBottom={'1px solid'}
                      borderColor={'black.4'}
                      width={'100%'}
                      _hover={{textTransform: 'none'}}
                      href={`#${t('landing.home.title')}`}>
                      {' '}
                      {t('landing.home.title')}
                    </Link>
                  </Box>
                </MenuItem>
                <MenuItem padding={'0'} bg={'black.1'}>
                  <Box width={'100%'} padding={'0 12px'}>
                    <Link
                      padding={'10px 0'}
                      display={'block'}
                      borderBottom={'1px solid'}
                      borderColor={'black.4'}
                      width={'100%'}
                      _hover={{textTransform: 'none'}}
                      href={`#${t('landing.features.title')}`}>
                      {' '}
                      {t('landing.features.title')}
                    </Link>
                  </Box>
                </MenuItem>

                <MenuItem padding={'0'} bg={'black.1'}>
                  <Box width={'100%'} padding={'0 12px'}>
                    <Link
                      padding={'10px 0'}
                      display={'block'}
                      borderBottom={'1px solid'}
                      borderColor={'black.4'}
                      width={'100%'}
                      _hover={{textTransform: 'none'}}
                      href={`#${t('landing.privacy.title')}`}>
                      {' '}
                      {t('landing.privacy.title')}
                    </Link>
                  </Box>
                </MenuItem>

                <MenuItem padding={'0'} bg={'black.1'}>
                  <Box width={'100%'} padding={'0 12px'}>
                    <Link
                      padding={'10px 0'}
                      display={'block'}
                      borderBottom={'1px solid'}
                      borderColor={'black.4'}
                      width={'100%'}
                      _hover={{textTransform: 'none'}}
                      href={`#${t('landing.plugins.title')}`}>
                      {' '}
                      {t('landing.plugins.title')}
                    </Link>
                  </Box>
                </MenuItem>

                <MenuItem padding={'0'} bg={'black.1'}>
                  <Box width={'100%'} padding={'0 12px'}>
                    <Link
                      padding={'10px 0'}
                      display={'block'}
                      borderBottom={'1px solid'}
                      borderColor={'black.4'}
                      width={'100%'}
                      _hover={{textTransform: 'none'}}
                      href={`#${t('landing.roadmap.title')}`}>
                      {' '}
                      {t('landing.roadmap.title')}
                    </Link>
                  </Box>
                </MenuItem>

                <MenuItem bg={'black.1'} padding={'0'} borderRadius={'0 0 6px 6px'}>
                  <Link
                    padding={'10px 12px'}
                    display={'block'}
                    width={'100%'}
                    _hover={{textTransform: 'none'}}
                    as={DomLink}
                    color={'accent.1'}
                    to={paths.authorization.path}>
                    {t('landing.signIn')}
                  </Link>
                </MenuItem>
                {/*<MenuItem>
                  <Link href={`#${HeadingGroupConfig[1].title}`}>Pricing</Link>
                </MenuItem>*/}
              </MenuList>
            </Menu>
          </Box>
          <Flex
            gridGap={'0 32px'}
            display={['none', 'none', 'flex']}
            color={'white.2'}
            fontWeight={'400'}
            justifyContent={'flex-end'}
            alignItems={'center'}>
            <Link href={`#${t('landing.home.title')}`}>{t('landing.home.title')}</Link>
            <Link href={`#${t('landing.features.title')}`}>{t('landing.features.title')}</Link>
            <Link href={`#${t('landing.privacy.title')}`}>{t('landing.privacy.title')}</Link>
            <Link href={`#${t('landing.plugins.title')}`}>{t('landing.roadmap.title')}</Link>
            <Link href={`#${t('landing.roadmap.title')}`}>{t('landing.roadmap.title')}</Link>
            <Link as={DomLink} color={'accent.1'} to={paths.authorization.path}>
              {t('landing.signIn')}
            </Link>
            {/* <Link href={`#${HeadingGroupConfig[1].title}`}>Pricing</Link>*/}
          </Flex>
        </GridItem>

        <Outlet />

        <GridItem area={'footer'} margin={'0 0 50px'}>
          <Grid gridTemplateColumns={['1fr', '1fr', '35% 1fr']}>
            <Box padding={['0 0 30px', '0 0 30px', '0 40px 0 0']}>
              <Logo />
            </Box>
            <Box
              justifyContent={'space-between'}
              display={['grid', 'grid', 'flex']}
              gridTemplateColumns={'1fr 1fr'}
              gridGap={['24px 0', '0']}>
              <FooterBlock
                name={t('landing.footer.website')}
                list={[
                  {
                    title: t('landing.home.title'),
                    link: '#',
                  },
                  {
                    title: t('landing.features.title'),
                    link: `#${t('landing.features.title')}`,
                  },
                  {
                    title: t('landing.privacy.title'),
                    link: `#${t('landing.privacy.title')}`,
                  },
                  {
                    title: t('landing.plugins.title'),
                    link: `#${t('landing.plugins.title')}`,
                  },
                  {
                    title: t('landing.roadmap.title'),
                    link: `#${t('landing.roadmap.title')}`,
                  },
                ]}
              />
              <FooterBlock
                name={t('landing.footer.connectWithUs')}
                list={[
                  {
                    title: t('landing.footer.email'),
                    link: 'mailto:support@nautime.io',
                  },
                  {
                    title: t('landing.footer.twitter'),
                    link: 'https://twitter.com/nau_time',
                  },
                  {
                    title: t('landing.footer.github'),
                    link: 'https://github.com/KaefDevelopment',
                  },
                  {
                    title: t('landing.footer.discord'),
                    link: 'https://discord.gg/qmZgxSwVZR',
                  },
                ]}
              />
              <FooterBlock
                name={t('landing.footer.legal')}
                list={[
                  {
                    title: t('landing.footer.privacyPolicy'),
                    link: '/privacy',
                    isInternal: true,
                  },
                  {
                    title: t('landing.footer.termsOfService'),
                    link: '/terms',
                    isInternal: true,
                  },
                ]}
              />
            </Box>
          </Grid>
        </GridItem>
      </Grid>
    </Box>
  )
}

export default LandingRouter
