import React, {FC} from 'react'
import {Toolkit, PressableBox, Icon} from 'components'
import {Box, Flex, Text} from '@chakra-ui/react'
import {ISliceData} from '@kaef/common/types'
import {ANIMATION_CHART_DURATION, colorFuse, percentFormatter, secondsToString} from '@kaef/common/utils/helpers'
import {emojiPalette} from 'services/chart'
import './SliceDataRow.css'

interface ISliceRow {
  disabled: boolean
  onClick: () => void
  sliceData: ISliceData
  total?: number
  withEmoji: boolean
  withToolkit?: boolean
  withOpacity?: boolean
  onEditProject?: (data: ISliceData) => void
  onToggleVisibility?: (data: ISliceData) => void
}

export const SliceDataRow: FC<ISliceRow> = ({
  disabled,
  onEditProject,
  onToggleVisibility,
  withToolkit,
  onClick,
  sliceData,
  total,
  withEmoji,
  withOpacity
}) => {
  const barWidth = total ? ((sliceData.seconds / total) * 100).toFixed(2) : sliceData.percent.toFixed(2)
  const isDisabled = sliceData.uuid === '00000000-0000-0000-0000-000000000000'
  return (
    <PressableBox
      disabled={disabled || isDisabled}
      onClick={onClick}
      mb={'8px'}
      withoutPadding
      toolkitWidth={64}
      opacity={withOpacity ? 0.6 : 1}
      toolkit={
        withToolkit ? (
          <Toolkit
            isVisible={sliceData.show}
            onEdit={() => onEditProject && onEditProject(sliceData)}
            onToggleVisibility={() => onToggleVisibility && onToggleVisibility(sliceData)}
          />
        ) : undefined
      }>
      <Flex w={'100%'} h={'40px'} alignItems={'center'} justifyContent={'space-between'} position={'relative'}>
        <Flex
          animation={`initial-bar-line ${ANIMATION_CHART_DURATION} 0s 1 ease-in`}
          borderRadius={'4px'}
          zIndex={-1}
          position={'absolute'}
          opacity={0.1}
          bgColor={sliceData.isLocked ? 'white.3' : colorFuse(sliceData.color)}
          width={`${barWidth}%`}
          top={0}
          left={0}
          h={'40px'}
          transition={`width ${ANIMATION_CHART_DURATION} ease-in`}
        />
        <Flex alignItems={'center'} pl={'10px'}>
          <Box
            mr={'4px'}
            filter={'drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.5))'}
            w={'14px'}
            h={'14px'}
            bgColor={sliceData.isLocked ? 'white.3-20' : colorFuse(sliceData.color)}
            borderRadius={'16px'}
          />
          {withEmoji && (
            <Flex ml={'6px'} w={'24px'} h={'24px'} mr={'4px'} alignItems={'center'} justifyContent={'center'}>
              {sliceData.isLocked && <Icon type={'lock'} color={'white.3'} />}
              {!sliceData.isLocked && <Text textStyle={'sm'}>{sliceData.icon || emojiPalette.getProjectEmoji(sliceData.uuid)}</Text>}
            </Flex>
          )}
          <Text textStyle={'md'} w={'50px'} textAlign={'left'} color={'white.3'}>
            {percentFormatter(sliceData.percent)}
          </Text>
          <Text
            overflow={'hidden'}
            maxWidth={['170px', '170px', '400px']}
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
            mr={'6px'}
            textStyle={'md'}
            color={'white.1'}>
            {sliceData.name}
          </Text>
        </Flex>
        <Flex>
          <Text textStyle={'md'} color={'white.3'} pr={'10px'}>
            {secondsToString(sliceData.seconds)}
          </Text>
        </Flex>
      </Flex>
    </PressableBox>
  )
}
