import {FC} from 'react'
import {Box, Flex, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Text} from '@chakra-ui/react'
import {ONBOARDING_CONFIG, RECOMMEND_CONFIG} from '@kaef/common/stores'
import {secondsToString} from '@kaef/common/utils/helpers'
import {durationMarksConfig, LABEL_STYLES} from 'scenes/Onboarding/Onboarding.utils'
import {useTranslation} from 'react-i18next'
import {useRemoteConfig} from 'services/firebase/useRemoteConfig'

interface IProps {
  duration: number
  setDuration: (value: number) => void
}

export const DurationModal: FC<IProps> = ({duration, setDuration}) => {
  const {t} = useTranslation()
  const {isGoalRecommendationEnabled} = useRemoteConfig()

  return (
    <>
      <Flex minHeight={'140px'}>
        <Flex flexDirection={'column'}>
          <Text mb={'6px'} textStyle={'lg'} color={'white.1'}>
            {t('onboarding.duration.title')}
          </Text>
          {isGoalRecommendationEnabled && duration > RECOMMEND_CONFIG.DURATION ? (
            <>
              <Text textStyle={'md'} color={'state.neutral'}>
                {t('onboarding.duration.subtitleRecommend', {value: secondsToString(RECOMMEND_CONFIG.DURATION)})}
              </Text>
              <Text color={'white.3'} cursor={'pointer'} onClick={() => setDuration(RECOMMEND_CONFIG.DURATION)}>
                {t('onboarding.duration.setRecommend')}
              </Text>
            </>
          ) : null}
        </Flex>
      </Flex>
      <Box pt={'15px'} pb={'15px'}>
        <Slider
          aria-label="slider-start"
          value={duration}
          min={ONBOARDING_CONFIG.TIME_STEP}
          max={ONBOARDING_CONFIG.TWELVE_HOURS}
          step={ONBOARDING_CONFIG.TIME_STEP}
          onChange={(val) => setDuration(val)}>
          {durationMarksConfig.map((item, idx) => (
            <SliderMark key={`${item}${idx}`} value={item.value} {...LABEL_STYLES}>
              {item.label}
            </SliderMark>
          ))}
          <SliderMark
            value={duration}
            textAlign="center"
            borderColor={'black.4'}
            bg={'black.2'}
            borderRadius={'8px'}
            borderWidth={'1px'}
            p={'10px'}
            color="white"
            m={'-85px 0 0 -75px'}>
            <Text>{secondsToString(duration, {withMinutes: true})}</Text>
            <Text textStyle={'sm'} color={'white.3'} whiteSpace={'nowrap'}>
              {t('onboarding.duration.sliderLabel')}
            </Text>
          </SliderMark>
          <SliderTrack height={'8px'}>
            <SliderFilledTrack bg={'accent.1-30'} />
          </SliderTrack>
          <SliderThumb width={'24px'} height={'24px'} bg={'accent.1'} />
        </Slider>
      </Box>
    </>
  )
}
