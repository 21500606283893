import {
  IOverviewDay,
  IOverviewGoal,
  IOverviewMonth,
  IOverviewWeek,
  IOverviewYear, TChangeDayTypeRequest,
  TOverviewRequest,
} from '@kaef/common/types'
import { AxiosInstance } from "axios";

export const getOverviewDay = async (
  data: TOverviewRequest,
  api: AxiosInstance,
): Promise<IOverviewDay> => {
  const {date, filter} = data
  const query = filter?.projectId ? `&projectId=${filter.projectId}` : ''
  const response = await api.get(`/summary/day?date=${date}${query}`)
  return response.data;
};

export const getOverviewWeek = async (
  data: TOverviewRequest,
  api: AxiosInstance,
): Promise<IOverviewWeek> => {
  const {date, filter} = data
  const query = filter?.projectId ? `&projectId=${filter.projectId}` : ''
  const response = await api.get(`/summary/week?date=${date}${query}`);
  return response.data;
};

export const getOverviewMonth = async (
  data: TOverviewRequest,
  api: AxiosInstance
): Promise<IOverviewMonth> => {
  const {date, filter} = data
  const query = filter?.projectId ? `&projectId=${filter.projectId}` : ''
  const response = await api.get(`/summary/month?date=${date}${query}`);
  return response.data;
};

export const getOverviewYear = async (
  data: TOverviewRequest,
  api: AxiosInstance,
): Promise<IOverviewYear> => {
  const {date, filter} = data
  const query = filter?.projectId ? `&projectId=${filter.projectId}` : ''
  const response = await api.get(`/summary/year?date=${date}${query}`);
  return response.data;
};

export const getOverviewGoals = async (
  data: TOverviewRequest,
  api: AxiosInstance,
): Promise<IOverviewGoal[]> => {
  const {date, filter, range} = data
  const query = filter?.projectId ? `&projectId=${filter.projectId}` : ''
  const response = await api.get(`/summary/goals/${range}?date=${date}${query}`);
  return response.data?.goals;
};

export const changeDayType = async (
  data: TChangeDayTypeRequest,
  api: AxiosInstance,
): Promise<void> => {
  await api.post(`/user/days/mark`, data);
};
