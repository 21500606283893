import React, {FC, memo, MouseEvent, useMemo, useRef, useState} from 'react'
import {Flex, Skeleton, Text, useToken} from '@chakra-ui/react'
import {IGoal, ISliceData, TMappedRange} from '@kaef/common/types'
import {
  DangerZone,
  generateDayStartEnd,
  generateTicks,
  GoalMarks,
  Grid, HiddenDivider,
  HoverLine,
  IconButton,
  Line,
  MicroPie,
  PressableBox,
  TDayLineRange,
  TimeLine,
} from 'components'
import {emojiPalette} from 'services/chart'
import {colorFuse, generateRangeValues, percentFormatter, secondsToString} from '@kaef/common/utils/helpers'

interface IProps {
  day: Date
  rangeStart: Date
  rangeEnd: Date
  goalStart?: IGoal
  goalEnd?: IGoal
  ranges?: TMappedRange[]
  onClick: (data: ISliceData) => void
  data?: ISliceData[]
  isLoading: boolean
  withEmoji: boolean
  withToolkit: boolean
  onEditProject: (data: ISliceData) => void
  onToggleVisibility: (data: ISliceData) => void
  disabled: boolean
}

interface IToolkitProps {
  onEdit: () => void
  onToggleVisibility: () => void
  isVisible: boolean
}

const Toolkit: FC<IToolkitProps> = ({onToggleVisibility, onEdit, isVisible}) => {
  const onEditClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onEdit && onEdit()
  }

  const onVisibleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onToggleVisibility && onToggleVisibility()
  }
  return (
    <Flex
      flexDirection={'column'}
      h={'100%'}
      borderWidth={'1px'}
      borderColor={'white.1-05'}
      backgroundColor={'black.3'}
      w={'32px'}
      marginLeft={'4px'}
      borderTopLeftRadius={'4px'}
      borderBottomLeftRadius={'4px'}
      borderTopRightRadius={'12px'}
      borderBottomRightRadius={'12px'}>
      <IconButton
        color={'white.3'}
        onClick={onEditClick}
        size="sm"
        iconProps={{width: 20, height: 20}}
        w={'32px'}
        h={'32px'}
        icon={'edit'}
        aria-label={'editProject'}
        variant={'ghost'}
      />
      <IconButton
        color={'white.3'}
        onClick={onVisibleClick}
        size="sm"
        iconProps={{width: 20, height: 20}}
        w={'32px'}
        h={'32px'}
        icon={isVisible ? 'eyeShow' : 'eyeHide'}
        aria-label={'editProject'}
        variant={'ghost'}
      />
    </Flex>
  )
}

const DayChartRaw: FC<IProps> = ({
  disabled,
  onEditProject,
  onToggleVisibility,
  withToolkit,
  goalStart,
  goalEnd,
  rangeStart,
  rangeEnd,
  isLoading,
  data,
  ranges,
  onClick,
  withEmoji
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [totalLine] = useToken('colors', ['white.3-20'])

  const [isShowHidden, setIsShowHidden] = useState(false)

  const {start, end, goalMarks, rangeDuration, offset, dangerZoneStart} = generateDayStartEnd(
    rangeStart,
    rangeEnd,
    goalStart,
    goalEnd
  )

  const ticks = generateTicks(start, end)
  const lines = useMemo(() => data?.filter(item => item.show === true || item.show === undefined).map((item) => {
    const color = colorFuse(item.color)
    const lineRanges = generateRangeValues(
      ranges?.filter((range) => range.id === item.mapId),
      start,
      end,
      color
    )
    return {
      ...item,
      color,
      lineRanges
    }
  }), [data])
  const hiddenLines = useMemo(() => data?.filter(item => item.show === false).map((item) => {
    const color = colorFuse(item.color)
    return {
      ...item,
      color,
      lineRanges: [],
    }
  }), [data])
  const totalLinesRanges = lines?.reduce((acc: TDayLineRange[], item) => [...acc, ...item.lineRanges], [])
  return (
    <Flex position={'relative'} flexDirection={'column'} mt={'20px'} ref={containerRef}>
      {isLoading && (
        <>
          <Skeleton borderRadius={'8px'} w={'100%'} h={'48px'} mb={'10px'} />
          <Skeleton borderRadius={'8px'} w={'100%'} h={'48px'} mb={'10px'} />
        </>
      )}
      {!isLoading && (
        <>
          <TimeLine ticks={ticks} />
          <Flex mb={'10px'}>
            {totalLinesRanges && <Line thinLine withTrail={false} singleColor={totalLine} ranges={totalLinesRanges} />}
          </Flex>
          <Grid ticks={ticks} />
          {lines?.map((item, index) => {
            return (
              <PressableBox
                disabled={disabled}
                mb={'10px'}
                key={index}
                onClick={() => onClick(item)}
                toolkitWidth={32}
                toolkit={
                  withToolkit ? (
                    <Toolkit
                      isVisible={item.show}
                      onEdit={() => onEditProject(item)}
                      onToggleVisibility={() => onToggleVisibility(item)}
                    />
                  ) : undefined
                }>
                <Flex flexDirection={'column'} pt={'10px'} pb={'8px'}>
                  <Flex w={'100%'} h={'24px'} alignItems={'center'} justifyContent={'space-between'} mb={'10px'}>
                    <Flex alignItems={'center'}>
                      <Flex w={'24px'} h={'24px'} mr={'4px'} alignItems={'center'} justifyContent={'center'}>
                        <MicroPie value={item.percent} color={item.color} />
                      </Flex>
                      {withEmoji && (
                        <Flex w={'24px'} h={'24px'} mr={'4px'} alignItems={'center'} justifyContent={'center'}>
                          <Text textStyle={'sm'}>{item.icon || emojiPalette.getProjectEmoji(item.uuid)}</Text>
                        </Flex>
                      )}
                      <Text
                        title={item.name}
                        overflow={'hidden'}
                        maxWidth={['170px', '170px', '400px']}
                        textOverflow={'ellipsis'}
                        whiteSpace={'nowrap'}
                        mr={'6px'}
                        textStyle={'md'}
                        color={'white.1'}>
                        {item.name}
                      </Text>
                      <Text textStyle={'md'} color={'white.3'}>
                        {percentFormatter(item.percent)}
                      </Text>
                    </Flex>
                    <Flex>
                      <Text textStyle={'md'} color={'white.3'}>
                        {secondsToString(item.seconds)}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Line ranges={item.lineRanges} />
                  </Flex>
                </Flex>
              </PressableBox>
            )
          })}
          {(hiddenLines?.length || 0) > 0 &&
            <HiddenDivider onToggle={setIsShowHidden} isShow={isShowHidden} length={hiddenLines?.length} />
          }
          {isShowHidden && hiddenLines?.map((item, index) => {
            return (
              <PressableBox
                disabled={disabled}
                opacity={0.6}
                mb={'10px'}
                key={index}
                onClick={() => onClick(item)}
                toolkitWidth={32}
                toolkit={
                  withToolkit ? (
                    <Toolkit
                      isVisible={item.show}
                      onEdit={() => onEditProject(item)}
                      onToggleVisibility={() => onToggleVisibility(item)}
                    />
                  ) : undefined
                }>
                <Flex flexDirection={'column'} pt={'10px'} pb={'8px'}>
                  <Flex w={'100%'} h={'24px'} alignItems={'center'} justifyContent={'space-between'} mb={'10px'}>
                    <Flex alignItems={'center'}>
                      <Flex w={'24px'} h={'24px'} mr={'4px'} alignItems={'center'} justifyContent={'center'}>
                        <MicroPie value={item.percent} color={item.color} />
                      </Flex>
                      {withEmoji && (
                        <Flex w={'24px'} h={'24px'} mr={'4px'} alignItems={'center'} justifyContent={'center'}>
                          <Text textStyle={'sm'}>{item.icon || emojiPalette.getProjectEmoji(item.uuid)}</Text>
                        </Flex>
                      )}
                      <Text
                        title={item.name}
                        overflow={'hidden'}
                        maxWidth={['170px', '170px', '400px']}
                        textOverflow={'ellipsis'}
                        whiteSpace={'nowrap'}
                        mr={'6px'}
                        textStyle={'md'}
                        color={'white.1'}>
                        {item.name}
                      </Text>
                      <Text textStyle={'md'} color={'white.3'}>
                        {percentFormatter(item.percent)}
                      </Text>
                    </Flex>
                    <Flex>
                      <Text textStyle={'md'} color={'white.3'}>
                        {secondsToString(item.seconds)}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Line ranges={item.lineRanges} />
                  </Flex>
                </Flex>
              </PressableBox>
            )
          })}

          <GoalMarks goalMarks={goalMarks} />
          <DangerZone start={dangerZoneStart} />
          <HoverLine offset={offset} rangeDuration={rangeDuration} containerRef={containerRef} />
        </>
      )}
    </Flex>
  )
}

export const DayChart = memo(DayChartRaw)
