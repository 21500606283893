import {addDays, addMonths, format, getDaysInMonth} from 'date-fns'
import {
  TBarData,
  IMap,
  IMonthInYear,
  IOverviewDayInWeek,
  ISliceData,
  IDayGranularity, ITotalDayInMonth
} from '../../types'
import {colorFuse} from './colors.helper'
import {secondsToString} from './date.helper'

export const generateYearAxisData = (firstDay: Date) => {
  const axisData = []
  for (let i = 0; i < 12; i++) {
    axisData.push(format(addMonths(firstDay, i), 'LLL'))
  }
  return axisData
}

export const generateYearBarData = (
  data: IMonthInYear[],
  sliceData: ISliceData[]
) => {
  const sliceMap: IMap<TBarData> = {}
  sliceData.forEach((slice) => {
    sliceMap[slice.mapId] = {
      isLocked: slice.isLocked,
      id: slice.uuid,
      name: slice.name,
      color: colorFuse(slice.color),
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  })
  data.forEach((month, index) => {
    month.totalList.forEach((item) => {
      if (sliceMap[item.id]) {
        sliceMap[item.id].data[index] = item.seconds
      } else {
        console.error('generateYearBarData: range mapId not matching slice data mapId')
      }
    })
  })
  return Object.values(sliceMap)
}

export const generateWeekAxisData = (firstDay: Date, mask: string = 'EEE, d') => {
  const axisData = []
  for (let i = 0; i < 7; i++) {
    axisData.push(format(addDays(firstDay, i), mask))
  }
  return axisData
}

export const generateMonthAxisData = (firstDay: Date) => {
  const axisData = []
  const length = getDaysInMonth(firstDay)
  for (let i = 1; i <= length; i++) {
    axisData.push(String(i))
  }
  return axisData
}

export const generateBarData = (
  data: IOverviewDayInWeek[] | IDayGranularity[],
  sliceData: ISliceData[],
  length: number,
  onlyCurMonth: boolean = false
) => {
  const sliceMap: IMap<TBarData> = {}
  sliceData.forEach((slice) => {
    sliceMap[slice.mapId] = {
      id: slice.uuid,
      name: slice.name,
      color: colorFuse(slice.color),
      data: new Array(length).fill(0),
      isLocked: slice.isLocked
    }
  })
  data.filter(item => item.isCurMonth || !onlyCurMonth).forEach((day, index) => {
    day.totalList.forEach((item) => {
      if (sliceMap[item.id]) {
        sliceMap[item.id].data[index] = item.seconds
      } else {
        console.error('generateBarData: range mapId not matching slice data mapId', item.id, sliceMap)
      }
    })
  })
  return Object.values(sliceMap)
}


export const getWeekTotalInMonth = (data: ITotalDayInMonth[]) => {
  const result: string[] = []
  let acc = 0
  for (let i = 0; i < data.length; i++) {
    if (i > 0 && (i + 1) % 7 === 0) {
      result.push(secondsToString(acc + data[i].value, {withWhitespace: true, withZero: true}))
      acc = 0
    } else {
      acc += data[i].value
    }
  }
  return result
}