import React, {FC} from 'react'
import {Box, Center, CircularProgress, Divider, Flex, Text} from '@chakra-ui/react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {EDayType, ERange} from '@kaef/common/types'
import {Icon, IconButton} from 'components'
import {api} from 'services/api'
import {prepareOverviewRequestData} from '@kaef/common/utils/helpers'
import {useOverviewStore} from '@kaef/common/stores'
import {useTranslation} from 'react-i18next'
import {format} from 'date-fns'
import {useQueryClient} from '@tanstack/react-query'
import {overviewKeys} from '@kaef/common/api/overview'
import {Dropdown} from 'components/UI/Dropdown/Dropdown'
import {paths} from 'shared/constants/paths'
import {logChangeDayType} from 'services/analytics/analytics'

interface IProps {

}

const DAYS_MAP = [
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
  'sun'
]

export const WeekPlan: FC<IProps> = ({}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
  const {range: rawRange, id} = useParams()
  const range = rawRange?.toLowerCase() as ERange

  const day = useOverviewStore((state) => state.day)
  const overviewDayQuery = api.useOverviewDayQuery(prepareOverviewRequestData(day, ERange.DAY, id))
  const weekPlanQuery = api.useWeekPlanQuery()
  const changeDayTypeMutation = api.useChangeDayTypeMutation()

  const dayType = overviewDayQuery.data?.dayType

  const isLoading = overviewDayQuery.isLoading || weekPlanQuery.isLoading

  const onEdit = () => {
    navigate(`${paths.editGoal.path}?type=DAYS`, {state: {backgroundLocation: location}})
  }

  const handleDayTypeChange = (type: EDayType) => {
    logChangeDayType(format(day, 'yyyy-MM-dd'), type)
    changeDayTypeMutation.mutate({
      date: format(day, 'yyyy-MM-dd'),
      type,
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(overviewKeys.day(prepareOverviewRequestData(day, ERange.DAY, id)))
        queryClient.invalidateQueries(overviewKeys.week(prepareOverviewRequestData(day, ERange.WEEK, id)))
        queryClient.invalidateQueries(overviewKeys.month(prepareOverviewRequestData(day, ERange.MONTH, id)))
        queryClient.invalidateQueries(overviewKeys.year(prepareOverviewRequestData(day, ERange.YEAR, id)))
        queryClient.invalidateQueries(overviewKeys.goalsByRange(prepareOverviewRequestData(day, ERange.DAY, id)))
        queryClient.invalidateQueries(overviewKeys.goalsByRange(prepareOverviewRequestData(day, ERange.WEEK, id)))
        queryClient.invalidateQueries(overviewKeys.goalsByRange(prepareOverviewRequestData(day, ERange.MONTH, id)))
        queryClient.invalidateQueries(overviewKeys.goalsByRange(prepareOverviewRequestData(day, ERange.YEAR, id)))
      }
    })
  }

  return (
    <Flex w={'100%'}
          className={'today-goal-hover-trigger'}
          bgColor={'white.1-05'}
          borderWidth={'1px'}
          position={'relative'}
          flexDirection={'column'}
          borderColor={'black.5-40'}
          borderRadius={'8px'}
          p={[0, '12px']}>
      {isLoading && (<Center><CircularProgress isIndeterminate color={'accent.1'} /></Center>)}
      {!isLoading && (
        <>
          {range === ERange.DAY && dayType && (
            <>
              <Dropdown<EDayType>
                width={'156px'}
                value={dayType}
                onChange={(value) => handleDayTypeChange(value as EDayType)}
                options={Object.values(EDayType).map(value => ({value, label: t(`sidebar.weekPlan.type.${value}`)}))} />
              <Divider orientation={'horizontal'} bgColor={'white.3-20'} mt={'12px'} mb={'12px'} />
            </>
          )}
          <Text mb={'8px'} textStyle={'md'} color={'white.3'}>{t('sidebar.weekPlan.title')}</Text>

          <Flex>
            {weekPlanQuery.data && DAYS_MAP.map((item, idx) => (
              <Flex key={`${item}${idx}`}
                    w={'36px'}
                    align={'center'}
                    justify={'center'}
                    position={'relative'}
                    h={'24px'}
                    mr={'4px'}
                    bgSize={!weekPlanQuery.data.includes(idx + 1) ? '4px 4px' : undefined}
                    bgImage={!weekPlanQuery.data.includes(idx + 1) ? 'linear-gradient(135deg, transparent 25%, white.1-10 25%, white.1-10 50%, transparent 50%, transparent 75%, white.1-10 75%, white.1-10 100%)' : undefined}
                    borderRadius={'4px'}
                    bgColor={weekPlanQuery.data.includes(idx + 1) ? 'white.1-10' : 'transparent'}>
                <Text textStyle={'sm'} color={'white.3'}>
                  {t(`common.calendar.${item}`)}
                </Text>
                {!weekPlanQuery.data.includes(idx + 1) && (
                  <Box position={'absolute'} left={'26px'} bottom={'18px'}>
                    <Icon width={14} height={14} type={'timeBreak'} color={'white.3'}/>
                  </Box>
                )}
              </Flex>
            ))}
          </Flex>
        </>
      )}

      <Flex
        className={'today-goal-actions-container'}
        position={'absolute'}
        w={'48px'}
        pl={'8px'}
        flexDirection={'column'}
        right={['4px', '-48px']}
        top={['4px', '-1px']}>
        <IconButton onClick={onEdit} mb={'8px'} icon={'edit'} aria-label={'edit-goal'} />
      </Flex>
    </Flex>
  )
}