import {FC, useEffect} from 'react'
import {Flex} from '@chakra-ui/react'
import {RECOMMEND_CONFIG, useOnboardingStore} from '@kaef/common/stores'
import {Button} from 'components'
import {useTranslation} from 'react-i18next'
import {logOnboardingSetGoal, logOnboardingSkipGoal} from 'services/analytics/analytics'
import {DurationModal} from 'components/Modals/Goals/DurationModal'

interface IProps {
  onNextStep: () => void
}

export const ChooseDuration: FC<IProps> = ({onNextStep}) => {
  const {t} = useTranslation()
  const [startValue, endValue, duration, setDuration] = useOnboardingStore((state) => [
    state.start,
    state.end,
    state.duration,
    state.setDuration
  ])
  const onNext = () => {
    logOnboardingSetGoal('DURATION')
    onNextStep()
  }

  useEffect(() => {
    if (endValue - startValue > RECOMMEND_CONFIG.DURATION) {
      setDuration(RECOMMEND_CONFIG.DURATION)
    } else {
      setDuration(endValue - startValue)
    }
  }, [])

  const onSkip = () => {
    logOnboardingSkipGoal('DURATION')
    onNextStep()
  }
  return (
    <Flex flexDirection={'column'} w={'100%'}>
      <DurationModal duration={duration} setDuration={setDuration} />
      <Flex w={'100%'} flexDirection={'column'}>
        <Button mb={'16px'} onClick={onNext} variant={'accent'}>
          {t('onboarding.duration.next')}
        </Button>
        <Button onClick={onSkip} variant={'secondary'}>
          {t('onboarding.duration.skip')}
        </Button>
      </Flex>
    </Flex>
  )
}
