import {FC, useEffect} from 'react'
import {Flex} from '@chakra-ui/react'
import {ONBOARDING_CONFIG, RECOMMEND_CONFIG, useOnboardingStore} from '@kaef/common/stores'
import {Button} from 'components'
import {useTranslation} from 'react-i18next'
import {logOnboardingSetGoal, logOnboardingSkipGoal} from 'services/analytics/analytics'
import {EndModal} from 'components/Modals/Goals/EndModal'

interface IProps {
  onNextStep: () => void
}

export const ChooseEnd: FC<IProps> = ({onNextStep}) => {
  const {t} = useTranslation()
  const [startValue, endValue, setEnd, setStart] = useOnboardingStore((state) => [
    state.start,
    state.end,
    state.setEnd,
    state.setStart
  ])
  const onNext = () => {
    logOnboardingSetGoal('END')
    onNextStep()
  }

  const onSkip = () => {
    logOnboardingSkipGoal('END')
    onNextStep()
  }

  useEffect(() => {
    if (startValue >= ONBOARDING_CONFIG.EIGHTEEN_HOURS || startValue >= endValue) {
      const adjustedFinish =
        startValue + RECOMMEND_CONFIG.DURATION > ONBOARDING_CONFIG.FULL_DAY
          ? ONBOARDING_CONFIG.FULL_DAY
          : startValue + RECOMMEND_CONFIG.DURATION
      setEnd(adjustedFinish)
    }
  }, [])

  return (
    <Flex flexDirection={'column'} w={'100%'}>
      <EndModal endValue={endValue} startValue={startValue} setEnd={setEnd} setStart={setStart} />
      <Flex w={'100%'} flexDirection={'column'}>
        <Button mb={'16px'} onClick={onNext} variant={'accent'}>
          {t('onboarding.finish.next')}
        </Button>
        <Button onClick={onSkip} variant={'secondary'}>
          {t('onboarding.finish.skip')}
        </Button>
      </Flex>
    </Flex>
  )
}
