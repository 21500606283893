import axios, {InternalAxiosRequestConfig} from 'axios'
import {NetworkInstance} from '@kaef/common/api'
import {auth} from '../firebase/firebase'
import {useAuthStore} from '@kaef/common/stores'

const REQUEST_TIMEOUT_ERROR = 30000

const apiInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_URL,
  timeout: REQUEST_TIMEOUT_ERROR,
  timeoutErrorMessage: 'Request timeout error',
  validateStatus: (status) => status < 400
})

export const api = new NetworkInstance(apiInstance)

let idTokenPromise: undefined | Promise<string> = undefined

apiInstance.interceptors.request.use(async (config: InternalAxiosRequestConfig<any>) => {
  if (!idTokenPromise) {
    idTokenPromise = auth.currentUser?.getIdToken()
  }
  const idToken = await idTokenPromise
  idTokenPromise = undefined
  if (!idToken) {
    useAuthStore.setState({isForceSignOut: true})
    throw 'error'
  }

  let data = config.data
  if (config.url === '/user') {
    data = {
      ...config.data,
      token: idToken
    }
  }

  return {
    ...config,
    data,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${idToken}`
    }
  } as InternalAxiosRequestConfig<any>
})

interface InternalAxiosRequestConfigExtended<T> extends InternalAxiosRequestConfig<T> {
  afterRefresh?: boolean
}

apiInstance.interceptors.response.use(async (response) => {
  if (response.status === 401) {
    const config: InternalAxiosRequestConfigExtended<any> = response.config
    if (config.afterRefresh) {
      useAuthStore.setState({isForceSignOut: true})
      throw 'error 401'
    }
    const {url, method, data} = response.config
    return apiInstance(url!, {
      method,
      data,
      // @ts-ignore
      afterRefresh: true
    })
  }
  return response
})
