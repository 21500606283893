import {FC, useMemo} from 'react'
import {Flex} from '@chakra-ui/react'
import {getWeekTotalInMonth, secondsToTime, toDate} from '@kaef/common/utils/helpers'
import {EGoalResult, IGoal, ITotalDayInMonth} from '@kaef/common/types'
import {BoxWithTime, CalendarLayout} from 'components'
import {parse} from 'date-fns'

interface IProps {
  onDayClick: (date: Date, isLocked?: boolean) => void
  data?: ITotalDayInMonth[]
  month: Date
  goalDuration?: IGoal
  goalDays?: IGoal
  withoutGoal?: boolean
}

export const TotalMonthChart: FC<IProps> = ({
                                              withoutGoal,
                                              onDayClick,
                                              goalDuration,
                                              month,
                                              data
                                            }) => {
  if (!data || !goalDuration) {
    return null
  }

  const max = useMemo(() => {
    let _max = 0
    const nonZeroDays = data.filter(val => val.value > 0)
    const total = nonZeroDays.reduce((acc, item) => {
      if (item.value > _max) {
        _max = item.value
      }
      return acc + item.value
    }, 0)
    if (nonZeroDays.length === 0) {
      return 1
    }
    return (2 * _max + total / nonZeroDays.length) / 3
  }, [data, goalDuration])


  const renderDay = (date: Date, index: number) => {
    const dayData = data[index]
    if (dayData) {
      return (
        <BoxWithTime
          withOpacity={!dayData.isCurMonth}
          withoutGoal={withoutGoal}
          onClick={() => onDayClick(date, dayData.result === EGoalResult.LOCKED)}
          formatter={secondsToTime}
          max={max}
          status={dayData.result}
          value={data[index]?.value}
        />
      )
    } else {
      return <></>
    }
  }

  return (
    <Flex flexDirection={'column'} w={'100%'} mb={'34px'}>
      <CalendarLayout daysArray={data.map(item => parse(item.day, 'yyyy-MM-dd', new Date()))} month={month}
                      sideColumnData={getWeekTotalInMonth(data)}
                      renderDay={renderDay} />
    </Flex>
  )
}
