import {FC} from 'react'
import {Flex} from '@chakra-ui/react'
import {ConfigPanel} from './components/ConfigPanel/ConfigPanel'
import {TodayGoals} from './components/TodayGoals/TodayGoals'
import {api} from 'services/api'
import {WeekPlan} from './components/WeekPlan/WeekPlan'

interface IOverviewSidebarProps {}

const OverviewSidebar: FC<IOverviewSidebarProps> = () => {
  const {data: user} = api.useUserInfoQuery()

  return (
    <Flex flexDirection={'column'} w={'100%'}>
      <WeekPlan />
      <TodayGoals />
      {user?.features?.includes('DEV') && <ConfigPanel />}
    </Flex>
  )
}

export default OverviewSidebar
