import { create } from "zustand";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "../../utils/helpers";

export const USER_TOKEN = "USER_TOKEN";

interface ITestState {
  userToken: string | null;
  setUserToken: (userToken: string) => void;
}

export const useTestStore = create<ITestState>()((set) => ({
  userToken: null,
  setUserToken: (userToken) => {
    set({ userToken });
  },
}));
