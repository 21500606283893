import React, {FC, useMemo} from 'react'
import {Center, CircularProgress, Flex, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {api} from 'services/api'
import {endOfWeek, format, isSameDay, isToday, parse, subDays} from 'date-fns'
import {MicroPie} from 'components'
import {goalSelector} from '@kaef/common/api/goals/goals.selector'
import {EGoalResult, EGoals, ERange, IOverviewGoal, TDayType} from '@kaef/common/types'
import {prepareOverviewRequestData} from '@kaef/common/utils/helpers'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {paths} from 'shared/constants/paths'
import {overviewDateByRangeSelector, useOverviewStore} from '@kaef/common/stores'
import {DurationGoalCard} from './components/DurationGoalCard/DurationGoalCard'
import {StartGoalCard} from './components/StartGoalCard/StartGoalCard'
import {EndGoalCard} from './components/EndGoalCard/EndGoalCard'
import {logOpenDay, logOpenPaywall} from 'services/analytics/analytics'


export const TodayGoals: FC = ({}) => {
  //TODO optimize rerenders when change chart type
  const navigate = useNavigate()
  const location = useLocation()
  const {t} = useTranslation()
  const {range: rawRange, id} = useParams()

  const range = rawRange?.toLowerCase() as ERange

  const setDay = useOverviewStore((state) => state.setDay)
  const overviewState = useOverviewStore(state => state)
  const date = overviewDateByRangeSelector(range, overviewState)

  const goalOverviewQuery = api.useOverviewGoalsQuery(prepareOverviewRequestData(date, range, id), {enabled: !!range})

  const isLoading = goalOverviewQuery.isLoading

  const {isShowGoalProgress, totalResult, totalGoal} = useMemo(() => {
    const noGoalStatuses: (EGoalResult|undefined)[] = [EGoalResult.NO_GOAL, EGoalResult.INDETERMINATE]
    const durationGoal = goalSelector('DURATION')(goalOverviewQuery.data)
    const startGoal = goalSelector('START')(goalOverviewQuery.data)
    const endGoal = goalSelector('END')(goalOverviewQuery.data)
    if (range !== ERange.DAY) {
      return {
        isShowGoalProgress: false,
        totalResult: 0,
        totalGoal: 0
      }
    }
    const totalGoal = (noGoalStatuses.includes(durationGoal?.history[0].result) ? 0 : 1) +
      (noGoalStatuses.includes(startGoal?.history[0].result) ? 0 : 1) +
      (noGoalStatuses.includes(endGoal?.history[0].result) ? 0 : 1)

    const totalResult = (durationGoal?.history[0].result === EGoalResult.SUCCESS ? 1 : 0) +
      (startGoal?.history[0].result === EGoalResult.SUCCESS ? 1 : 0) +
      (endGoal?.history[0].result === EGoalResult.SUCCESS ? 1 : 0)
    const isShowGoalProgress = totalGoal > 0

    return {
      isShowGoalProgress,
      totalResult,
      totalGoal
    }
  }, [goalOverviewQuery.data])

  const getRangeTitle = () => {
    switch (range) {
      case ERange.DAY:
        if (isToday(date)) {
          return t('sidebar.todayGoals.today')
        }
        if (isSameDay(date, subDays(new Date(), 1))) {
          return t('sidebar.todayGoals.yesterday')
        }
        return format(date, 'dd MMM, yyyy')
      case ERange.WEEK:
        return `${format(date, 'dd MMM')} - ${format(endOfWeek(date, {weekStartsOn: 1}), 'dd MMM yyyy')}`
      case ERange.MONTH:
        return format(date, 'MMMM')
      case ERange.YEAR:
        return format(date, 'yyyy')
      default:
        return ''
    }
  }

  const onOpenEditGoal = (type: EGoals) => {
    navigate(`${paths.editGoal.path}?type=${type}`, {state: {backgroundLocation: location}})
  }

  const onClickDay = (date: string, result: EGoalResult, dayType: TDayType | null) => {
    if (!date) {
      return
    }
    if (result === EGoalResult.LOCKED) {
      logOpenPaywall('goals')
      navigate(paths.paywall.path, {state: {backgroundLocation: location}})
      return
    }
    const day = parse(date, 'yyyy-MM-dd', new Date())
    logOpenDay('goals', dayType, result)
    setDay(day)
    navigate(`${paths.overview.path}/day`)
  }

  const renderGoalsCard = (goal: IOverviewGoal) => {
    switch (goal.type) {
      default:
        return null
      case 'DURATION':
        return <DurationGoalCard key={goal.type} goal={goal} range={range} onClickDay={onClickDay} onEdit={() => onOpenEditGoal('DURATION')} />
      case 'START':
        return <StartGoalCard key={goal.type} goal={goal} range={range} onClickDay={onClickDay} onEdit={() => onOpenEditGoal('START')} />
      case 'END':
        return <EndGoalCard key={goal.type} goal={goal} range={range} onClickDay={onClickDay} onEdit={() => onOpenEditGoal('END')} />
    }
  }

  return (
    <Flex flexDirection={'column'} w={'100%'} mt={'24px'}>
      {isLoading && (<Center><CircularProgress isIndeterminate color={'accent.1'} /></Center>)}
      {!isLoading && (
        <>
          <Flex width={['100%']} mb={'12px'} h={'24px'} justifyContent={'space-between'} alignItems={'center'}>
            <Text textStyle={'md'} color={'white.3'}>
              {t('sidebar.todayGoals.title', {value: getRangeTitle()})}
            </Text>
            {isShowGoalProgress && (
              <Flex alignItems={'center'}>
                <MicroPie withBlur={false} value={(totalResult / (totalGoal || 1)) * 100} color={'white.1'} />
                <Text ml={'6px'} textStyle={'lg'}>
                  {totalResult}/{totalGoal} {t('sidebar.todayGoals.goals')}
                </Text>
              </Flex>
            )}
          </Flex>

          {goalOverviewQuery.data?.map(goal => renderGoalsCard(goal))}

        </>
      )}
    </Flex>
  )
}

