import {useDaySliceStore, useMonthSliceStore, useWeekSliceStore, useYearSliceStore} from '../../stores'
import {ERange, ISlice, TChart} from '../../types'


export const useCurrentChartSlice = () => {
  const [dayChart, daySlice] = useDaySliceStore((state) => [state.chart, state.slice])
  const [weekChart, weekSlice] = useWeekSliceStore((state) => [state.chart, state.slice])
  const [monthChart, monthSlice] = useMonthSliceStore((state) => [state.chart, state.slice])
  const [yearChart, yearSlice] = useYearSliceStore((state) => [state.chart, state.slice])


  const getCurrentChartSlice = (range: ERange): [chart: TChart, slice: ISlice | undefined] => {
    switch (range) {
      case ERange.DAY:
        return [dayChart, daySlice]
      case ERange.WEEK:
        return [weekChart, weekSlice]
      case ERange.MONTH:
        return [monthChart, monthSlice]
      case ERange.YEAR:
        return [yearChart, yearSlice]
      default:
        return [dayChart, daySlice]
    }
  }
  return getCurrentChartSlice
}