import {ERange, TOverviewRequest} from '../../types'
import {format, startOfMonth, startOfWeek, startOfYear} from 'date-fns'

export const prepareOverviewRequestData = (day: Date, range: ERange, projectId?: string ): TOverviewRequest => {
  let date = format(day, 'yyyy-MM-dd')
  switch(range) {
    case ERange.WEEK:
      date = format(startOfWeek(day, {weekStartsOn: 1}), 'yyyy-MM-dd')
      break
    case ERange.MONTH:
      date = format(startOfMonth(day), 'yyyy-MM-dd')
      break
    case ERange.YEAR:
      date = format(startOfYear(day), 'yyyy-MM-dd')
      break
  }
  if (!projectId) {
    return {
      date,
      range,
    }
  }
  return {
    date,
    range,
    filter: {
      projectId
    }
  }
}

