import {FC} from 'react'
import {Flex, Text} from '@chakra-ui/react'
import {Button, Input} from 'components'
import {useTranslation} from 'react-i18next'
import {useOnboardingStore} from '@kaef/common/stores'

interface IProps {
  onNextStep: () => void
}
export const ChooseName: FC<IProps> = ({onNextStep}) => {
  const {t} = useTranslation()
  const [name, setName] = useOnboardingStore((state) => [state.name, state.setName])

  const onNext = () => {
    onNextStep()
  }

  const onSkip = () => {
    onNextStep()
  }

  const onChange = (event: any) => {
    setName(event.target.value)
  }

  return (
    <Flex flexDirection={'column'} w={'100%'}>
      <Flex mb={'32px'} minHeight={'104px'}>
        <Flex flexDirection={'column'}>
          <Text mb={'6px'} textStyle={'lg'} color={'white.1'}>
            {t('onboarding.name.title')}
          </Text>
          <Text textStyle={'md'} color={'white.3'}>
            {t('onboarding.name.subtitle')}
          </Text>
        </Flex>
      </Flex>
      <Input
        onChange={onChange}
        value={name}
        mb={'24px'}
        placeholder={String(t('onboarding.name.placeholder'))}
        size="md"
      />
      <Flex w={'100%'} flexDirection={'column'}>
        <Button mb={'16px'} onClick={onNext} variant={'accent'}>
          {t('onboarding.name.next')}
        </Button>
        <Button onClick={onSkip} variant={'secondary'}>
          {t('onboarding.name.skip')}
        </Button>
      </Flex>
    </Flex>
  )
}
